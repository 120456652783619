import getConfig from 'next/config'
import { Dispatch, SetStateAction, useContext } from 'react'

import { useCurrencyRates } from '@nx/api/hooks'
import { FireCurrencyModal } from '@nx/fire/currency-modal'

import { UserContext } from '@web/context/UserInfoProvider'
import { NOT_DEFINED_CURRENCY } from '@web/helpers/enums'

interface CurrencyModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const { publicRuntimeConfig } = getConfig()

export function CurrencyModal({ isOpen, setIsOpen }: CurrencyModalProps) {
  const { data: currencyRates } = useCurrencyRates(
    publicRuntimeConfig.WEB_API_URL
  )
  const { currencyConfig, setCurrencyConfig } = useContext(UserContext)

  return (
    <FireCurrencyModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      currencyConfig={currencyConfig}
      setCurrencyConfig={setCurrencyConfig}
      currencyRates={currencyRates}
      notDefinedCookieValue={NOT_DEFINED_CURRENCY}
    />
  )
}
