import { useCallback, useEffect, useState } from 'react'

import { useDataLayer } from '@nx/utils/google-tag-manager'

import { EVENT_EMAIL_SIGNUP_TRIGGER } from './constants'
import { getDismissCookie, getSubscriptionCookie } from './cookies'
import { FireSignupWidgetProps } from './types'

export const useNewsletterSignupBanner = ({
  isVisibleTrigger,
  isVisibleFooter,
}: FireSignupWidgetProps) => {
  const dataLayer = useDataLayer()
  const [isPopupShown, setIsPopupShown] = useState(false)
  const [isBannerShown, setIsBannerShown] = useState(false)
  const [isSubscribed] = useState(() => getSubscriptionCookie())

  const queryTrigger =
    typeof window !== 'undefined' &&
    new URL(window.location.href).searchParams.get('email-signup')

  const handlePopupTrigger = useCallback(() => {
    dataLayer.push({
      event: EVENT_EMAIL_SIGNUP_TRIGGER,
    })
    setIsPopupShown(true)
    setIsBannerShown(false)
  }, [dataLayer])

  useEffect(() => {
    if (queryTrigger) {
      handlePopupTrigger()
    }
  }, [handlePopupTrigger, queryTrigger])

  useEffect(() => {
    if (getDismissCookie()) return

    setIsBannerShown(!isVisibleFooter && isVisibleTrigger)
  }, [isVisibleTrigger, isVisibleFooter])

  return {
    isSubscribed,
    isBannerShown,
    isPopupShown,
    queryTrigger,
    handlePopupTrigger,
    setIsBannerShown,
    setIsPopupShown,
  }
}
