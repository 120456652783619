import { axiosInstance } from '@nx/fetch'

import { DepartmentResponse } from '../types'

export const departmentApiURL = '/api/departments/v1/'

export async function getDepartments<
  ResponseFields extends keyof DepartmentResponse,
>(
  baseURL: string,
  params: {
    onlyCategories?: boolean
    codes?: string[]
    locations?: string[]
    fields?: (keyof DepartmentResponse)[]
  }
) {
  const url = new URL(departmentApiURL, baseURL)

  if (params) {
    const searchParams = new URLSearchParams(
      Object.entries(params)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => [key, value.toString()])
    )
    url.search = searchParams.toString()
  }

  try {
    const response = await axiosInstance.get<
      | DepartmentResponse[]
      | Pick<DepartmentResponse, ResponseFields | 'department'>[]
    >(url.toString(), {
      baseURL,
    })
    return response.data
  } catch {
    return null
  }
}
