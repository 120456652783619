import { IncomingMessage } from 'http'
import { GetServerSidePropsContext } from 'next'

export type LaunchDarklyKey = string

export interface BonhamsCookies {
  bonhams_access?: string
  bonhams_token?: string
  bonhams_sid?: string
  userDissmissCookieBox?: 'agree'
  ld_id?: LaunchDarklyKey
  xm_id?: string
}

export interface OneTrustCookies {
  OptanonConsent?: string
  OptanonAlertBoxClosed?: string
}

export enum OneTrustGroup {
  'Strictly Necessary' = 'C0001',
  'Performance' = 'C0002',
  'Functional' = 'C0003',
  'Targeting' = 'C0004',
}

export interface CustomContext extends Omit<GetServerSidePropsContext, 'req'> {
  req: IncomingMessage & {
    cookies: BonhamsCookies & OneTrustCookies
  }
}

export enum Brand {
  bonhams = 'bonhams',
  skinner = 'skinner',
  bukowskis = 'bukowskis',
  bruunRasmussen = 'bruun_rasmussen',
  cornette = 'cornette',
  cars = 'bonhams-cars',
  carsOnline = 'bonhams-cars-online',
}

export type EBrand = `${Brand}`

export enum MaintenanceMode {
  partial = 'partial',
  full = 'full',
}

export enum URLLotType {
  lot = 'lot',
  preview = 'preview-lot',
}

export enum LDCURLLotType {
  lot = 'lot',
  preview = 'preview_lot',
}
