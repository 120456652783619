import getConfig from 'next/config'
import { ParsedUrlQuery } from 'querystring'

import {
  populateUrl as populateUrlLib,
  urlWithPersistentQueryParams as urlWithPersistentQueryParamsLib,
} from '@nx/populate-url'

const { publicRuntimeConfig } = getConfig()

export function populateUrl(
  path: string,
  ...params: (Record<string, string | number | boolean> | string)[]
) {
  return populateUrlLib(publicRuntimeConfig.WEB_BASE_URL, path, ...params)
}

export function urlWithPersistentQueryParams(
  url: string,
  query: ParsedUrlQuery = {}
) {
  return urlWithPersistentQueryParamsLib(url, query)
}
