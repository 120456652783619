import styled, { css } from 'styled-components'

import { StyledButton } from '@nx/fire/button'

export const StyledSkipLink = styled(StyledButton)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -3rem);
    transition: transform 0.2s;
    opacity: 0;

    ${theme.media.up.lg} {
      left: 1rem;
      transform: translate(0, -3rem);
    }

    &:focus {
      background: ${theme.colours.primary.white};
      z-index: ${theme.zIndex.skipToContent};
      transform: translate(-50%, 1rem);
      opacity: 1;

      ${theme.media.up.lg} {
        transform: translate(0, 1rem);
      }
    }
  `}
`
