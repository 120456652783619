import Image from 'next/image'
import { rgba } from 'polished'
import styled from 'styled-components'

import { FireButton } from '@nx/fire/button'
import { FireFormSearchInput } from '@nx/fire/form-input'
import { FireModal } from '@nx/fire/modal'
import { Body } from '@nx/fire/typography'

export const StyledFireModal = styled(FireModal)`
  ${({ theme }) => theme.media.up.lg} {
    && {
      min-width: 660px;
      max-height: 90vh;
      height: 90vh;
    }
  }
`

export const SelectedCurrencyLabel = styled(Body)`
  font-weight: 600;
`

export const StyledActions = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 27px;
  gap: 20px;

  ${({ theme }) => theme.media.up.xs} {
    gap: 60px;
  }
`

export const StyledCloseButton = styled(FireButton)`
  width: 90px;

  ${({ theme }) => theme.media.up.lg} {
    width: 190px;
  }
`

export const StyledSeparator = styled.hr`
  width: 100%;
  border: 0;
  ${({ theme }) => theme.helpers.topSeparationLine}
  height: 1px;
  box-sizing: border-box;
  margin: 30px 0;
  text-align: left;
`

export const StyledFlagList = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: calc(100% - 84px);
`

export const StyledFlags = styled.div`
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledCurrencyRow = styled.li`
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colours.grey['15']};
  }
`

export const StyledFlagRow = styled.button`
  all: unset;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }) =>
      `${rgba(theme.colours.primary.primary, 0.1)}`};
  }
`

export const StyledFlagSymbols = styled.div`
  display: flex;
  align-items: center;
`

export const StyledBody = styled(Body)`
  margin: 0;
  margin-left: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const StyledCurrencies = styled(Body)`
  margin: 0;
  padding-left: 7px;
`

export const StyledFlag = styled(Image)`
  border-radius: 5px;
  height: 32px;
  width: 32px;
`

export const StyledHeaderContainer = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 20px;

  &:has(> :only-child) {
    grid-template-columns: 1fr;
  }
`

export const StyledFireFormSearchInput = styled(FireFormSearchInput)`
  align-self: end;
`
