import styled, { css } from 'styled-components'
import { Body } from '@nx/fire/typography'
import {
  CheckIcon,
  CloseIcon,
  ExclamationPointIcon,
  QuestionMarkIcon,
  WarningIcon,
} from '@nx/fire/assets'

const sharedIconStyle = css`
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  height: 25px;
  padding: 2px;
  width: 25px;
`

export const StyledSuccessIcon = styled(CheckIcon)`
  ${({ theme }) => css`
    border-color: ${theme.colours.status.success};
    color: ${theme.colours.status.success};
    ${sharedIconStyle}
  `}
`

export const StyledErrorIcon = styled(CloseIcon)`
  ${({ theme }) => css`
    border-color: ${theme.colours.status.error};
    color: ${theme.colours.status.error};
    padding: 3px;
    ${sharedIconStyle}
  `}
`

export const StyledInfoIcon = styled(QuestionMarkIcon)`
  ${({ theme }) => css`
    border-color: ${theme.colours.status.info};
    color: ${theme.colours.status.info};
    ${sharedIconStyle}
  `}
`

export const StyledAttentionIcon = styled(ExclamationPointIcon)`
  ${({ theme }) => css`
    border-color: ${theme.colours.status.info};
    color: ${theme.colours.status.info};
    ${sharedIconStyle}
  `}
`

export const StyledWarningIcon = styled(WarningIcon)`
  ${({ theme }) => css`
    border-color: ${theme.colours.status.warning};
    color: ${theme.colours.status.warning};
    padding: 0px 2px 2px;
    ${sharedIconStyle}
  `}
`

export const StyledCloseButton = styled.button`
  ${({ theme }) => css`
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    height: 42px;
    padding: 12px;
    pointer-events: auto;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 42px;

    &:hover,
    &:focus {
      color: ${theme.colours.primary.primary};
    }

    svg {
      width: 100%;
      height: 100%;
    }
  `}
`

export const StyledBody = styled(Body)`
  margin: 0;
`
