import * as Sentry from '@sentry/nextjs'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { isServer } from '@nx/helpers'
import { logger } from '@nx/utils/logger'

import { axiosRetryInterceptor, isTheLastRetry } from './helper-fetch-retry'

export const axiosConfig: AxiosRequestConfig = {
  timeout: 5_000,
  transitional: {
    clarifyTimeoutError: true,
  },
}

const axiosInstanceMain = axios.create(axiosConfig)

axiosInstanceMain.interceptors.response.use(
  (response) => response,
  (error: AxiosError | Error) => {
    if (axios.isAxiosError(error) && isTheLastRetry(error)) {
      const serverSideLabel = isServer() ? 'SERVER' : 'CLIENT'
      const { response, config } = error

      if (response?.status?.toString().startsWith('4')) {
        throw error
      }

      logger.error(
        error,
        `[${serverSideLabel}] Axios response interceptor error`
      )

      Object.defineProperty(error, 'isServerSide', {
        value: isServer(),
        enumerable: true,
      })

      Object.defineProperty(error, 'message', {
        /* istanbul ignore next */
        value: `[${serverSideLabel}] ${error.message} - ${error.config?.method?.toUpperCase()} ${error.config?.url} - ${error.response?.status}`,
        enumerable: true,
      })

      logger.error(
        error,
        `[${serverSideLabel}] Failed retrieving data from ${/* istanbul ignore next */ config?.url} with status: ${
          /* istanbul ignore next */ response?.status || error.code
        }`
      )

      if (!response?.status || response.status >= 500) {
        Sentry.captureException(error)
      }

      if (typeof window === 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        const newrelic = require('newrelic')
        newrelic.noticeError(error)
      } else {
        /* istanbul ignore next */
        window.newrelic?.noticeError?.(error)
      }
    }

    throw error
  }
)

export const axiosInstance = axiosRetryInterceptor(axiosInstanceMain)
