import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { GeoIPRegionResponse, getRegion } from '@nx/api'

export function useGetRegion(
  baseUrl: string,
  ip: string,
  enabled = true
): UseGetRegionReturnProps {
  const { data, error, isSuccess, isFetching, isError } = useQuery({
    queryKey: ['region', ip],
    queryFn: () => getRegion(baseUrl, ip),
    enabled: !!ip && enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    retryOnMount: false,
    staleTime: 60 * 60 * 1000, // 1 hour
  })

  return {
    data,
    isSuccess,
    isFetching,
    isError,
    error,
  }
}

export interface UseGetRegionReturnProps
  extends Pick<UseQueryResult, 'isSuccess' | 'isFetching' | 'isError'> {
  data?: GeoIPRegionResponse | null
  error: Error | null
}
