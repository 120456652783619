import { useQuery } from '@tanstack/react-query'
import Cookies from 'js-cookie'

import { UserDataResponse } from '../../types/'
import { checkUserLoginStatus } from '../check-user-login-status'

export interface QueryCheckUserLoginStatusResponse {
  isLoggedIn: boolean
  user: Partial<UserDataResponse> & { iClientNo: number }
}

export interface UseCheckUserLoginStatusProps {
  apiUrl: string
}

export function useCheckUserLoginStatus({
  apiUrl,
}: UseCheckUserLoginStatusProps) {
  const { status, error, data } = useQuery({
    queryKey: ['user-login-status'],
    queryFn: async () => {
      const tokenCookie = Cookies.get('bonhams_token')

      return await checkUserLoginStatus({ tokenCookie, apiUrl })
    },
    enabled: Boolean(Cookies.get('bonhams_token')),
    refetchOnWindowFocus: true,
  })

  return {
    status,
    error,
    data: data
      ? data
      : {
          isLoggedIn: false,
          user: { iClientNo: 0 },
        },
  }
}
