import { stripLocaleFromPath } from '@nx/smartling'

import {
  auctionRedirects,
  carsRedirects,
  cornetteDeSaintCyrBERedirects,
  cornetteDeSaintCyrFRRedirects,
  cornetteSubDomainRedirects,
  departmentRedirects,
  howToBuyRedirects,
  skinnerIncRedirects,
  skinnerSubDomainRedirects,
  specialistRedirects,
} from './mappings'
import { RedirectConfig } from './mappings/redirectMappingsTypes'

export function cleanDoubleSlashes(url: string) {
  return url.replace(/([^:])(\/\/+)/g, '$1/')
}

/**
 * Rules for redirect mappings:
 *  source
 *    - must only include pathname and querystring and must include trailing slash
 *  destination:
 *    - Can take a series of replacement items which are formed like so: {{0}}
 *    - be careful when adding a / after the hostname when adding replacements
 */
const globalRedirectMappings = {
  'www.skinnerinc.com': skinnerIncRedirects,
  'www.cornettedesaintcyr.fr': cornetteDeSaintCyrFRRedirects,
  'www.cornettedesaintcyr.be': cornetteDeSaintCyrBERedirects,
  'www.bonhams.com': addEndSlash([
    ...auctionRedirects,
    ...carsRedirects,
    ...departmentRedirects,
    ...specialistRedirects,
  ]),
  'skinner.bonhams.com': skinnerSubDomainRedirects,
  'csc.bonhams.com': cornetteSubDomainRedirects,
  howToBuyRedirects: [...howToBuyRedirects],
}

function addEndSlash(config: RedirectConfig[]) {
  return config.map((item) => {
    const destination = item.destination.endsWith('/')
      ? item.destination
      : `${item.destination}/`
    return { ...item, destination }
  })
}

export type KeyOfGlobalRedirectMappings = keyof typeof globalRedirectMappings

export function redirectManager({
  hostname,
  path,
  domains,
}: {
  hostname?: string
  path: string
  domains: KeyOfGlobalRedirectMappings[]
}): {
  permanent: boolean
  destination: string
  basePath?: false
} | null {
  let result = null

  const redirects = domains
    .map((domain) => globalRedirectMappings[domain])
    .flat()

  for (const redirect of redirects) {
    let pathname = ''
    let querystring = ''

    try {
      const pathUrl = new URL(path)
      if (!pathUrl.hostname.includes('bonhams.com')) {
        break
      }

      pathname = pathUrl.pathname
    } catch {
      pathname = path
    }

    if (
      hostname !== 'www.cornettedesaintcyr.fr' &&
      hostname !== 'www.cornettedesaintcyr.be' &&
      hostname !== 'www.skinnerinc.com'
    ) {
      pathname = stripLocaleFromPath(pathname)
    }

    if (pathname.includes('?')) {
      const [pathnameSplit, querystringSplit] = pathname.split('?')

      pathname = pathnameSplit
      querystring = `?${querystringSplit}`
    }

    if (!pathname.endsWith('/')) {
      pathname = `${pathname}/`
    }

    if (!pathname.startsWith('/')) {
      pathname = `/${pathname}`
    }

    const pathMatch = pathname.match(new RegExp(redirect.source))
    let hostnameMatch = true

    if (
      typeof redirect.hostname !== 'undefined' &&
      typeof hostname === 'undefined'
    ) {
      hostnameMatch = false
    }

    if (
      typeof redirect.hostname !== 'undefined' &&
      typeof hostname !== 'undefined'
    ) {
      const hostnameWithProtocol =
        hostname.startsWith('http://') || hostname.startsWith('https://')
          ? hostname
          : `https://${hostname}`
      const redirectHostnameWithProtocol =
        redirect.hostname.startsWith('http') ||
        redirect.hostname.startsWith('https://')
          ? redirect.hostname
          : `https://${redirect.hostname}`

      const { hostname: userHostname } = new URL(hostnameWithProtocol)
      const { hostname: redirectHostname } = new URL(
        redirectHostnameWithProtocol
      )

      const hostnameRegex = new RegExp(`^${redirectHostname}$`)

      hostnameMatch = hostnameRegex.test(userHostname)
    }

    if (pathMatch?.length && hostnameMatch) {
      const hasReplacements = /{{\d}}/.test(redirect.destination)
      let destination = redirect.destination

      if (hasReplacements) {
        pathMatch.forEach((_, index) => {
          if (destination.includes(`{{${index}}}`)) {
            destination = destination.replace(
              `{{${index}}}`,
              pathMatch[index + 1]
            )
          }
        })
      }

      if (!destination.includes('?')) {
        destination = `${destination}/`
      }

      if (querystring.length > 0) {
        destination = `${destination}${querystring}`
      }

      result = {
        permanent: redirect.permanent,
        destination,
      }

      break
    }
  }

  return result
    ? { ...result, destination: cleanDoubleSlashes(result.destination) }
    : result
}
