import NextLink, { LinkProps } from 'next/link'
import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react'

export const FireLink = forwardRef<HTMLAnchorElement, FireLinkProps>(
  ({ href, forceAnchor, prefetch, children, ...rest }, ref) => {
    const isExternalLink =
      href?.startsWith('http') ||
      href?.startsWith('tel') ||
      href?.startsWith('blob') ||
      href?.startsWith('mailto')

    return isExternalLink || Boolean(forceAnchor) ? (
      <a {...rest} href={href} data-testid="plain-anchor" ref={ref}>
        {children}
      </a>
    ) : (
      <NextLink {...rest} href={href} prefetch={prefetch} ref={ref}>
        {children}
      </NextLink>
    )
  }
)

export type FireLinkProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> &
  LinkProps & {
    forceAnchor?: boolean
  }
