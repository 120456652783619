import { parseCookieRequestHeader } from '@nx/cookies'

import { CheckCookieBoxDismissed, CookiesTypes } from '../types/'

export function checkCookieBoxDismissed({
  context,
  appContext,
}: CheckCookieBoxDismissed): boolean {
  let cookies: CookiesTypes = {}

  if (context?.req?.cookies) {
    cookies = context.req?.cookies
  }

  if (appContext?.ctx?.req?.headers?.cookie) {
    cookies = parseCookieRequestHeader(appContext.ctx.req.headers.cookie)
  }

  return Boolean(cookies.OptanonAlertBoxClosed)
}
