import { forwardRef } from 'react'

import {
  StyledErrorTextContainerDiv,
  StyledErrorTextDiv,
  StyledIconContainer,
  StyledInput,
  StyledInputContainer,
} from './fire-form-input.styles'

export interface FireFormInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean
  errorTexts?: string[]
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  isLarge?: boolean
  type?: string
}

export const FireFormInput = forwardRef<HTMLInputElement, FireFormInputProps>(
  (
    {
      errorTexts,
      hasError,
      startIcon,
      endIcon,
      isLarge,
      className,
      type = 'text',
      ...inputProps
    },
    ref
  ) => (
    <>
      <StyledInputContainer className={className}>
        {startIcon && (
          <StyledIconContainer $iconPosition="start">
            {startIcon}
          </StyledIconContainer>
        )}
        <StyledInput
          {...inputProps}
          ref={ref}
          $hasError={hasError}
          $hasStartIcon={!!startIcon}
          $hasEndIcon={!!endIcon}
          $isLarge={isLarge}
          as="input"
          $level={isLarge ? 'L' : 'S'}
          type={type}
        />
        {endIcon && (
          <StyledIconContainer $iconPosition="end">
            {endIcon}
          </StyledIconContainer>
        )}
      </StyledInputContainer>
      <StyledErrorTextContainerDiv $hasError={hasError}>
        {errorTexts?.map((errorText, index) => (
          <StyledErrorTextDiv key={index}>{errorText}</StyledErrorTextDiv>
        ))}
      </StyledErrorTextContainerDiv>
    </>
  )
)

export default FireFormInput
