import styled, { css } from 'styled-components'

import { FireFormInput, FireFormInputProps } from './fire-form-input'

export const StyledSearchInput = styled(FireFormInput)<FireFormInputProps>`
  width: 100%;

  input {
    margin: 0;
    padding-right: 35px;
  }
`

export const StyledSearchSubmit = styled.button`
  ${({ theme }) => css`
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colours.primary.primary};
    height: 32px;
    width: 32px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      color: ${theme.colours.primary.black};
    }

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  `}
`
