import { AxiosError, AxiosResponse } from 'axios'

import { axiosInstance } from '@nx/fetch'

import {
  UpdatePasswordErrorRequestResponse,
  UpdatePasswordProps,
  UpdatePasswordRequestResponse,
  UpdatePasswordReturn,
} from '../types/'

export async function updatePassword({
  apiUrl,
  clientNo,
  password,
  token,
}: UpdatePasswordProps): Promise<UpdatePasswordReturn> {
  return axiosInstance
    .post(
      `/api/com/client/reset-password/${clientNo}/`,
      {
        newPassword: password,
        newPasswordConfirmation: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
        baseURL: apiUrl,
      }
    )
    .then((e: AxiosResponse<UpdatePasswordRequestResponse>) => {
      return {
        status: 'OK',
        message: e.data['Message'],
      }
    })
    .catch((e: AxiosError<UpdatePasswordErrorRequestResponse>) => {
      return {
        status: 'ERROR',
        message: e.response?.data.detail || 'Password could not be changed.',
      }
    })
}
