import * as React from 'react'
import { css, keyframes } from 'styled-components'
import { Keyframes } from 'styled-components/dist/types'

import { EBrand } from '@nx/global-types'

import {
  ReactComponent as BonhamsLogoIcon,
  default as BonhamsLogoSVG,
} from './assets/bonhamsLogo.svg'
import {
  fontSangBleuSunrise,
  fontSangBleuSunriseLivre,
  fontSourceSans3,
} from './fonts'

const baseFontSize = 16

export const rounded = (calc: number) =>
  calc >> 0 === calc ? calc : +calc.toFixed(5)
export const fontSize = (px: number) => `${rounded(px / baseFontSize)}rem`
export const lineHeight = (lineHeight: number, fontSize: number) =>
  rounded(lineHeight / fontSize)

const breakpoints = {
  xs: 375,
  sm: 426,
  md: 576,
  lg: 768,
  xl: 992,
  xxl: 1200,
}

const colours: MasterTheme['colours'] = {
  primary: {
    primary: '#8331a7',
    black: '#000000',
    charcoal: '#4f4e4f',
    white: '#ffffff',
  },
  secondary: {
    primary: '#62247d',
    secondary: '#0063a1',
  },
  tertiary: {
    primary: '#ffffff',
  },
  background: {
    main: '#ffffff',
  },
  grey: {
    90: '#3d3d3b', // darkGrey
    75: '#8a8a87', // midGrey
    60: '#ababab', // greyAB
    45: '#eaeae9', // lightGrey
    30: '#f7f7f7', // greyF7
    15: '#fafafa', // lighterGrey
  },
  status: {
    error: '#c90000',
    success: '#00a11f',
    info: '#00b3c9',
    warning: '#F56600',
  },
}

const shadows: MasterTheme['shadows'] = {
  extraSmall: '0 0 5px 3px rgba(0,0,0,0.01)',
  small: '0 0 10px 3px rgba(0,0,0,0.05)',
  smallBottomOnly: '0px 5px 5px 0px rgba(0,0,0,0.05)',
  large: '0 0 15px 5px rgba(0,0,0,0.1)',
}

const fontFamilies: Record<
  'sangbleu' | 'sourceSans' | 'sangbleuLivre',
  string
> = {
  sangbleu: fontSangBleuSunrise.style.fontFamily,
  sourceSans: fontSourceSans3.style.fontFamily,
  sangbleuLivre: fontSangBleuSunriseLivre.style.fontFamily,
}

const fontStyles: MasterTheme['fontStyles'] = {
  sangbleu: {
    regular: css`
      font-family: ${fontFamilies.sangbleu};
      font-weight: 400;
    `,
    medium: css`
      font-family: ${fontFamilies.sangbleu};
      font-weight: 500;
    `,
  },
  sangbleuLivre: css`
    font-family: ${fontFamilies.sangbleuLivre};
    font-weight: 400;
  `,
  sourceSans: {
    light: css`
      font-family: ${fontFamilies.sourceSans};
      font-weight: 300;
    `,
    regular: css`
      font-family: ${fontFamilies.sourceSans};
      font-weight: 400;
    `,
    medium: css`
      font-family: ${fontFamilies.sourceSans};
      font-weight: 600;
    `,
    bold: css`
      font-family: ${fontFamilies.sourceSans};
      font-weight: 700;
    `,
  },
}

const typography: MasterTheme['typography'] = {
  heading: {
    heading1: css`
      ${fontStyles.sangbleu.regular}
      color: ${colours.primary.black};
      font-size: ${fontSize(80)};
      line-height: ${lineHeight(88, 80)};

      b,
      strong {
        ${fontStyles.sangbleu.medium}
      }
    `,
    heading2: css`
      ${fontStyles.sangbleu.regular}
      color: ${colours.primary.black};
      font-size: ${fontSize(32)};
      line-height: ${lineHeight(44, 32)};

      b,
      strong {
        ${fontStyles.sangbleu.medium}
      }

      ${getUpMedia(breakpoints.xl)} {
        font-size: ${fontSize(52)};
        line-height: ${lineHeight(64, 52)};
      }
    `,
    heading3: css`
      ${fontStyles.sangbleu.medium}
      color: ${colours.primary.black};
      font-size: ${fontSize(24)};
      line-height: ${lineHeight(36, 24)};

      ${getUpMedia(breakpoints.xl)} {
        font-size: ${fontSize(32)};
        line-height: ${lineHeight(44, 32)};
      }
    `,
    heading4: css`
      ${fontStyles.sangbleu.medium}
      color: ${colours.primary.black};
      font-size: ${fontSize(24)};
      line-height: ${lineHeight(36, 24)};
    `,
    heading5: css`
      ${fontStyles.sangbleu.medium}
      color: ${colours.primary.black};
      font-size: ${fontSize(20)};
      line-height: ${lineHeight(32, 20)};
    `,
    heading6: css`
      ${fontStyles.sangbleu.medium}
      color: ${colours.primary.black};
      font-size: ${fontSize(16)};
      line-height: ${lineHeight(28, 16)};
    `,
  },
  body: {
    L: css`
      ${fontStyles.sourceSans.regular}
      color: ${colours.primary.black};
      font-size: ${fontSize(20)};
      line-height: ${lineHeight(32, 20)};
    `,
    M: css`
      ${fontStyles.sourceSans.regular}
      color: ${colours.primary.black};
      font-size: ${fontSize(16)};
      line-height: ${lineHeight(26, 16)};
    `,
    S: css`
      ${fontStyles.sourceSans.regular}
      color: ${colours.primary.black};
      font-size: ${fontSize(14)};
      line-height: ${lineHeight(24, 14)};
    `,
    subtitle: css`
      ${fontStyles.sangbleu.regular}
      color: ${colours.primary.black};
      font-size: ${fontSize(14)};
      line-height: ${lineHeight(24, 14)};
    `,
    XS: css`
      ${fontStyles.sourceSans.regular}
      color: ${colours.primary.black};
      font-size: ${fontSize(12)};
      line-height: ${lineHeight(18, 12)};
    `,
  },
  label: {
    XL: css`
      ${fontStyles.sourceSans.bold}
      color: ${colours.primary.black};
      font-size: ${fontSize(20)};
      letter-spacing: ${lineHeight(1.33, 20)}em;
      line-height: ${lineHeight(30, 20)};
      text-transform: uppercase;
    `,
    L: css`
      ${fontStyles.sourceSans.bold}
      color: ${colours.primary.black};
      font-size: ${fontSize(18)};
      letter-spacing: ${lineHeight(1.2, 18)}em;
      line-height: ${lineHeight(24, 18)};
      text-transform: uppercase;
    `,
    M: css`
      ${fontStyles.sourceSans.bold}
      color: ${colours.primary.black};
      font-size: ${fontSize(16)};
      letter-spacing: ${lineHeight(1.07, 16)}em;
      line-height: ${lineHeight(20, 16)};
      text-transform: uppercase;
    `,
    S: css`
      ${fontStyles.sourceSans.bold}
      color: ${colours.primary.black};
      font-size: ${fontSize(14)};
      letter-spacing: ${lineHeight(0.9, 14)}em;
      line-height: ${lineHeight(18, 14)};
      text-transform: uppercase;
    `,
    XS: css`
      ${fontStyles.sourceSans.bold}
      color: ${colours.primary.black};
      font-size: ${fontSize(12)};
      letter-spacing: ${lineHeight(0.8, 12)}em;
      line-height: ${lineHeight(16, 12)};
      text-transform: uppercase;
    `,
  },
}

export const bonhamsTheme: MasterTheme = {
  name: 'bonhams',
  breakpoints,
  fontStyles,
  colours,
  shadows,
  typography,
  fonts: {
    baseSize: baseFontSize,
    headerFont: fontFamilies.sangbleu,
    bodyFont: fontFamilies.sourceSans,
  },
  logo: {
    component: BonhamsLogoIcon,
    svg: BonhamsLogoSVG,
  },
  footerLogo: {
    component: BonhamsLogoIcon,
    svg: BonhamsLogoSVG,
  },
  media: {
    down: {
      sm: getDownMedia(breakpoints.sm),
      md: getDownMedia(breakpoints.md),
      lg: getDownMedia(breakpoints.lg),
      xl: getDownMedia(breakpoints.xl),
      xxl: getDownMedia(breakpoints.xxl),
    },
    up: {
      xs: getUpMedia(breakpoints.xs),
      sm: getUpMedia(breakpoints.sm),
      md: getUpMedia(breakpoints.md),
      lg: getUpMedia(breakpoints.lg),
      xl: getUpMedia(breakpoints.xl),
      xxl: getUpMedia(breakpoints.xxl),
    },
  },
  spacing: {
    verticalSpacer: { small: 50, large: 70 },
  },
  zIndex: {
    slider: 10,
    fullWidthPromoBanner: 10,
    imageHeader: 10,
    dropdown: 20,
    auctionPageLostConnection: 20,
    lotCardLike: 20,
    stickyBar: 30,
    stickyMaintenanceBar: 40,
    languageDetectionBanner: 50,
    filters: 60,
    popup: 65,
    header: 70,
    headerSearch: 80,
    headerNavigation: 80,
    skipToContent: 80,
    headerNavigationDropdown: 90,
    headerLanguageDropdown: 90,
    cookieBanner: 100,
    chatBubble: 150,
    newsletterBanner: 160,
    modal: 200,
  },
  helpers: {
    fakeOutline: `
    border-color: #5b9dd9;
    border-width: 3px;
      border-style: solid;
      `,
    ctaLink: `
    color: ${colours.primary.primary};
    text-transform: uppercase;
    margin-right: 30px;
    cursor: pointer;
    font-size: .75rem;
    font-weight: 700;
    font-family: ${fontFamilies.sourceSans};
    border: none;
    background: rgba(255, 255, 255, 0);
    padding: 0;
    `,
    fadeOut: (backgroundColour) => `
    width: 100%;
    height: 110px;
    background: linear-gradient(0deg, ${backgroundColour} 11%, rgba(255, 255, 255, 0));
    `,
    noLinkUnderline: css`
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    `,
    bottomSeparationLine: css`
      border-bottom: 1px solid ${colours.grey[45]};
    `,
    topSeparationLine: css`
      border-top: 1px solid ${colours.grey[45]};
    `,
    borders: css`
      border: 1px solid ${colours.grey[45]};
    `,
  },
  animation: {
    rotate360: keyframes`
      0% {
        transform: rotate(0deg)
      }
      100% {
        transform: rotate(360deg)
      }
    `,
  },
  components: {
    FireImageCarousel: {
      Caption: {
        root: css`
          ${typography.body.S}
          ${fontStyles.sourceSans.medium}
        `,
      },
    },
  },
}

function getDownMedia(size: number): string {
  return `@media (max-width: ${size - 1}px)`
}

function getUpMedia(size: number): string {
  return `@media (min-width: ${size}px)`
}

export interface MasterTheme {
  name: EBrand | 'vms' | 'email'
  colours: {
    primary: Record<'primary' | 'black' | 'charcoal' | 'white', string>
    secondary: Record<'primary' | 'secondary', string>
    tertiary: Record<'primary', string>
    background: Record<'main', string>
    grey: Record<90 | 75 | 60 | 45 | 30 | 15, string>
    status: Record<'error' | 'success' | 'info' | 'warning', string>
  }
  logo: {
    component: React.FunctionComponent<
      React.PropsWithChildren<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
    >
    svg: string
  }
  footerLogo: {
    component: React.FunctionComponent<
      React.PropsWithChildren<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined
        }
      >
    >
    svg: string
  }
  shadows: Record<'extraSmall' | 'small' | 'smallBottomOnly' | 'large', string>
  breakpoints: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl', number>
  media: {
    down: Record<'sm' | 'md' | 'lg' | 'xl' | 'xxl', string>
    up: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl', string>
  }
  fontStyles: {
    sangbleu: Record<'regular' | 'medium', ReturnType<typeof css>>
    sangbleuLivre: ReturnType<typeof css>
    sourceSans: Record<
      'light' | 'regular' | 'medium' | 'bold',
      ReturnType<typeof css>
    >
  }
  typography: {
    heading: Record<`heading${1 | 2 | 3 | 4 | 5 | 6}`, ReturnType<typeof css>>
    body: Record<'L' | 'M' | 'S' | 'subtitle' | 'XS', ReturnType<typeof css>>
    label: Record<'XL' | 'L' | 'M' | 'S' | 'XS', ReturnType<typeof css>>
  }
  fonts: {
    baseSize: number
    headerFont: string
    bodyFont: string
  }
  spacing: { verticalSpacer: { small: number; large: number } }
  zIndex: Record<
    | 'slider'
    | 'fullWidthPromoBanner'
    | 'imageHeader'
    | 'dropdown'
    | 'auctionPageLostConnection'
    | 'lotCardLike'
    | 'stickyBar'
    | 'stickyMaintenanceBar'
    | 'popup'
    | 'languageDetectionBanner'
    | 'filters'
    | 'header'
    | 'headerSearch'
    | 'headerNavigation'
    | 'skipToContent'
    | 'headerNavigationDropdown'
    | 'headerLanguageDropdown'
    | 'cookieBanner'
    | 'newsletterBanner'
    | 'chatBubble'
    | 'modal',
    number
  >
  helpers: {
    fakeOutline: string
    ctaLink: string
    fadeOut: (backgroundColour: string) => string
    noLinkUnderline: ReturnType<typeof css>
    borders: ReturnType<typeof css>
    bottomSeparationLine: ReturnType<typeof css>
    topSeparationLine: ReturnType<typeof css>
  }
  animation: {
    rotate360: Keyframes
  }
  components?: {
    HeroImageCarousel?: {
      Bullets?: ReturnType<typeof css>
      InfoBoxContainer?: ReturnType<typeof css>
      ItemContainer?: ReturnType<typeof css>
      ImageContainer?: ReturnType<typeof css>
      PrevArrow?: ReturnType<typeof css>
      NextArrow?: ReturnType<typeof css>
    }
    FireCarousel?: {
      PageButton?: {
        root?: ReturnType<typeof css>
        active?: ReturnType<typeof css>
      }
      PageArrow?: ReturnType<typeof css>
    }
    FireImageCarousel?: {
      Caption?: {
        root?: ReturnType<typeof css>
      }
    }
    FireHeader?: {
      NavItem?: {
        root?: ReturnType<typeof css>
        active?: ReturnType<typeof css>
      }
    }
    FireFormLabel?: ReturnType<typeof css>
    FireFormInput?: ReturnType<typeof css>
  }
}
