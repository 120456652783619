import { useRouter } from 'next/router'
import {
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

/* istanbul ignore next */
const ScrollPositionContext = createContext<{
  restoreScrollPosition: () => void
  elementsToRecordHeightRefs: MutableRefObject<
    Record<string, HTMLDivElement | HTMLImageElement>
  >
}>({
  restoreScrollPosition: () => null,
  elementsToRecordHeightRefs: { current: {} },
})

export const useScrollPositionRestore = () => useContext(ScrollPositionContext)

export const ScrollPositionProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const router = useRouter()
  const elementsToRecordHeightRefs = useRef<Record<string, HTMLDivElement>>({})
  const [lotHeights, setLotHeights] = useState<Record<string, number>>({})
  const [scrollY, setScrollY] = useState(0)
  const [isBack, setIsBack] = useState(false)

  typeof window !== 'undefined' &&
    router.beforePopState(() => {
      setIsBack(true)
      return true
    })

  const handleRouteChangeComplete = useCallback(() => {
    // Make sure user is completing a client side route via the back button
    if (!isBack) return
    if (!scrollY) return

    Object.entries(lotHeights).forEach(([id, height]) => {
      const domEl = elementsToRecordHeightRefs.current[id]

      /* Set the lot cards to their previous height so we can
      successfully scroll to where the user previously was */
      /* istanbul ignore else */
      if (domEl) {
        domEl.setAttribute('style', `min-height: ${height}px`)
      }
    })

    window.scrollTo({
      top: scrollY,
      behavior: 'instant',
    })

    /* Ensure the styles are removed after the user tries to
    change the viewport width - any attempt earlier than that can
    cause the scrolling above not to work consistently */
    const removeStyles = () => {
      Object.entries(elementsToRecordHeightRefs.current).forEach(
        ([_, domEl]) => {
          /* istanbul ignore else */
          if (domEl) {
            domEl.removeAttribute('style')
          }
        }
      )

      window.removeEventListener('resize', removeStyles)
    }

    window.addEventListener('resize', removeStyles)

    setLotHeights({})
    setIsBack(false)
    setScrollY(0)
  }, [isBack, scrollY, lotHeights])

  const handleRouteChangeStart = useCallback(() => {
    setScrollY(window.scrollY)

    // record the heights of each lot on the page
    setLotHeights(() => {
      const heights: Record<string, number> = {}

      Object.entries(elementsToRecordHeightRefs.current).forEach(([id, el]) => {
        heights[id] = el.offsetHeight
      })

      return heights
    })
  }, [])

  const restoreScrollPosition = useCallback(() => {
    /* istanbul ignore else */
    if (window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
  }, [router, handleRouteChangeStart, handleRouteChangeComplete])

  useEffect(() => {
    return () => {
      if (window.history.scrollRestoration) {
        window.history.scrollRestoration = 'auto'
      }

      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router, handleRouteChangeStart, handleRouteChangeComplete])

  return (
    <ScrollPositionContext.Provider
      value={{
        restoreScrollPosition,
        elementsToRecordHeightRefs,
      }}
    >
      {children}
    </ScrollPositionContext.Provider>
  )
}
