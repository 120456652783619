import NextImage, { ImageProps } from 'next/image'
import { memo, useState } from 'react'
import isEqual from 'react-fast-compare'
import styled, { css } from 'styled-components'

import { useNewImageSubdomain } from '@nx/helpers'

import PlaceholderImage from './placeholder.svg'

export const FireImage = memo(
  ({
    placeholderPadding = true,
    disableBackgroundPlaceholder = false,
    onLoad,
    placeholderImage = PlaceholderImage,
    placeholderAlt = 'Bonhams logo',
    src,
    ...imageProps
  }: FireImageProps) => {
    const { addNewImageSubdomain } = useNewImageSubdomain()

    const [disableBackground, setDisableBackground] = useState(
      disableBackgroundPlaceholder
    )
    const [showImage, setShowImage] = useState(Boolean(src))

    /* istanbul ignore next */
    const finalImage = typeof src == 'string' ? addNewImageSubdomain(src) : src

    return showImage ? (
      <StyledImage
        $placeholderPadding={placeholderPadding}
        $disableBackgroundPlaceholder={disableBackground}
        onLoad={(event) => {
          setDisableBackground(true)
          onLoad?.(event)
        }}
        onError={() => setShowImage(false)}
        sizes="100vw"
        src={finalImage}
        {...imageProps}
      />
    ) : (
      <StyledPlaceholder
        sizes="100vw"
        {...imageProps}
        src={placeholderImage}
        $placeholderPadding={placeholderPadding}
        alt={placeholderAlt}
      />
    )
  },
  isEqual
)

export interface FireImageProps extends ImageProps {
  placeholderPadding?: boolean
  placeholderImage?: string
  placeholderAlt?: string
  disableBackgroundPlaceholder?: boolean
}

const StyledImage = styled(NextImage)<
  ImageProps & {
    $placeholderPadding: boolean
    $disableBackgroundPlaceholder: boolean
  }
>`
  ${({ $placeholderPadding, $disableBackgroundPlaceholder }) => css`
    ${!$disableBackgroundPlaceholder &&
    css`
      background-image: url(${PlaceholderImage});
    `}
    background-origin: content-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: ${$placeholderPadding ? '90% 90%' : '100% 100%'};
    font-size: 1px;
  `}
`

const StyledPlaceholder = styled(NextImage)<
  ImageProps & { $placeholderPadding: boolean }
>`
  ${({ $placeholderPadding }) => css`
    font-size: 1px;
    padding: ${$placeholderPadding ? '5% !important' : '0'};
  `}
`
