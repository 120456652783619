import getConfig from 'next/config'

import { checkUserLoginStatus } from '@nx/auth'

const { publicRuntimeConfig } = getConfig()

export interface GetLoginStatusProps {
  tokenCookie?: string
}

export function getLoginStatus({ tokenCookie }: GetLoginStatusProps) {
  return checkUserLoginStatus({
    tokenCookie,
    apiUrl: publicRuntimeConfig.CLIENT_SERVICE_URL,
  })
}
