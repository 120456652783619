import { OneTrustGroup } from '@nx/global-types'
import { isOneTrustCookieGroupEnabled } from '@nx/one-trust'

import { GetLaunchDarklyKeyProps, GetLaunchDarklyKeyResponse } from './types'

export const LD_ID_COOKIE_NAME = 'ld_id'

export const LD_QUERY_KEY = ['launchDarklyFlags']

export function getLaunchDarklyKey({
  cookies = {},
  withOneTrust,
  ldId,
}: GetLaunchDarklyKeyProps): GetLaunchDarklyKeyResponse {
  const isGroupEnabled = withOneTrust
    ? isOneTrustCookieGroupEnabled(OneTrustGroup.Performance, cookies)
    : true

  return {
    key:
      ldId ||
      (isGroupEnabled
        ? Math.floor(Math.random() * (9999 - 1 + 1) + 1).toString()
        : '1'),
    isNew: isGroupEnabled && !ldId,
  }
}
