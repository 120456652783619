import getConfig from 'next/config'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

export const carsRedirects: RedirectConfig[] = [
  {
    source: /^\/cars(.*)/,
    destination: publicRuntimeConfig.CARS_BASE_URL,
    permanent: true,
  },
]
