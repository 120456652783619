import styled, { css } from 'styled-components'

import { ChevronDownIcon } from '@nx/fire/assets'
import { FireButton, FireButtonProps } from '@nx/fire/button'
import { FireLink, FireLinkProps } from '@nx/fire/link'

export const Wrapper = styled.header`
  ${({ theme }) => css`
    background: ${theme.colours.primary.white};
    position: relative;
    z-index: ${theme.zIndex.header};

    ${theme.media.up.xl} {
      box-shadow: ${theme.shadows.small};
    }
  `}
`

export const TopGrid = styled.div`
  ${({ theme }) => css`
    align-items: center;
    box-shadow: ${theme.shadows.small};
    display: flex;
    justify-content: center;
    padding: 18px 15px;
    position: relative;
    z-index: ${theme.zIndex.header};

    ${theme.media.up.xl} {
      box-shadow: none;
      padding: 14px 20px;
    }
  `}
`

export const CentrePositioner = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1170px;
  width: 100%;
`

export const DesktopAccountNav = styled.nav`
  ${({ theme }) => css`
    display: none;

    ${theme.media.up.xl} {
      display: flex;
      margin-left: auto;
      position: relative;
      min-height: 29px;

      > ${ButtonNavItem},
        > div
        > ${ButtonNavItem},
        > ${LinkNavItem},
        > div
        > ${LinkNavItem} {
        ${theme.typography.label.M}
        align-items: flex-end;
        align-self: flex-end;
        color: ${theme.colours.primary.charcoal};
        line-height: 15px;
        margin: 0;
        padding-bottom: 0;
        padding-top: 5px;

        ${ButtonNavItem},
        ${LinkNavItem} {
          ${theme.typography.body.M}
          text-transform: none;
        }
      }
    }
  `}
`

export const LogoWrapper = styled.div`
  ${({ theme }) => css`
    align-items: flex-end;
    display: flex;
    flex-shrink: 1;
    justify-content: center;
    padding: 0 10px;

    ${theme.media.up.xl} {
      justify-content: start;
      padding: 0;
    }
  `}
`
export const LogoLink = styled(FireLink)`
  ${({ theme }) => css`
    align-items: flex-end;
    box-sizing: content-box;
    display: flex;
    flex-basis: 133px;
    padding: 0 5px;

    svg {
      height: 100%;
      width: 100%;
    }

    ${theme.media.up.xl} {
      flex-basis: 163px;
      height: 27px;
      margin-right: 15px;
      padding: 0;

      svg {
        padding: 0;
      }
    }
  `}
`

export const MenuButton = styled(FireButton)<
  (FireButtonProps | FireLinkProps) & { $isOpen: boolean }
>`
  ${({ theme, $isOpen }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 37px;
    justify-content: space-between;
    margin-left: -4px;
    padding: 7px 4px;
    position: relative;
    width: 37px;

    &::before,
    &::after,
    span {
      content: ' ';
      background-color: ${theme.colours.primary.primary};
      border-radius: 3px;
      clip: auto;
      clip-path: none;
      height: 4px;
      opacity: 1;
      position: relative;
      transform: rotate(0deg) translate(0, 0) scaleX(100%);
      transition:
        opacity 0.3s ease,
        transform 0.3s ease,
        color 0.3s ease;
      width: 100%;
    }

    ${$isOpen &&
    css`
      &::before {
        background-color: ${theme.colours.primary.black};
        transform: rotate(45deg) translate(7px, 7px) scaleX(115%);
      }

      &::after {
        background-color: ${theme.colours.primary.black};
        transform: rotate(-45deg) translate(6px, -7px) scaleX(115%);
      }

      span {
        opacity: 0;
      }
    `}

    ${theme.media.up.xl} {
      display: none;
    }
  `}
`

export const SearchButton = styled(FireLink)<FireLinkProps>`
  ${({ theme }) => css`
    color: ${theme.colours.grey[90]};
    flex-shrink: 0;
    height: 37px;
    margin-right: -6px;
    width: 37px;

    svg {
      height: 100%;
      width: 100%;
    }

    ${theme.media.up.xl} {
      display: none;
    }
  `}
`

export const MainNav = styled.nav<{ $show: boolean }>`
  ${({ theme, $show }) => css`
    background-color: ${theme.colours.grey[30]};
    display: ${$show ? 'flex' : 'none'};
    height: calc(100vh - 74px);
    left: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 100%;

    ${CentrePositioner} {
      align-items: unset;
    }

    ${theme.media.up.xl} {
      background-color: ${theme.colours.primary.white};
      display: flex;
      height: auto;
      justify-content: center;
      left: auto;
      padding: 0 20px;
      position: relative;
      right: auto;
      top: auto;
      z-index: 0;

      ${CentrePositioner} {
        align-items: center;
        flex-direction: row;
      }

      & > div > div > ${ButtonNavItem}, & > div > div > ${LinkNavItem} {
        &:first-of-type {
          padding-left: 0;
        }
      }
    }
  `}
`

const sharedNavItem = ({
  $isActive,
  $isMobileOnly,
  $isExpanded,
}: {
  $isActive?: boolean
  $isMobileOnly?: boolean
  $isExpanded?: boolean
}) => css`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colours.grey[45]};
    color: ${$isActive
      ? theme.colours.primary.primary
      : theme.colours.grey[90]};
    display: flex;
    height: auto;
    justify-content: unset;
    padding: 20px 17px;
    white-space: nowrap;
    text-decoration: none;

    &:focus,
    &:hover {
      color: ${theme.colours.primary.primary};
      text-decoration: none;
    }

    > svg {
      align-self: center;
      height: 24px;
      width: 24px;
    }

    ${StyledChevronDownIcon} {
      height: 18px;
      margin-left: auto;
      transform: rotate(0deg);
      transition: transform 0.3s ease;
      width: 18px;

      ${$isExpanded && 'transform: rotate(-180deg);'}
    }

    ${theme.media.up.xl} {
      border: 0;
      display: ${$isMobileOnly ? 'none' : 'flex'};
      margin: 2px 0;
      padding: 10px 15px;

      &:hover {
        ${StyledChevronDownIcon} {
          transform: rotate(-180deg);
        }
      }

      ${StyledChevronDownIcon} {
        margin-left: 6px;
      }

      > svg {
        align-self: flex-end;
        display: inline-flex;
        transform: translateY(4px);
      }
    }
    ${theme.components?.FireHeader?.NavItem?.root}
    ${$isActive ? theme.components?.FireHeader?.NavItem?.active : ''}
  `}
`

export const LinkNavItem = styled(FireLink)<
  FireLinkProps & {
    $isActive?: boolean
    $isMobileOnly?: boolean
    $isExpanded?: boolean
  }
>`
  ${({ $isActive, $isMobileOnly, $isExpanded }) =>
    sharedNavItem({
      $isActive,
      $isMobileOnly,
      $isExpanded,
    })}
`

export const ButtonNavItem = styled(FireButton)<
  FireButtonProps & {
    $isActive?: boolean
    $isMobileOnly?: boolean
    $isExpanded?: boolean
  }
>`
  ${({ $isActive, $isMobileOnly, $isExpanded }) =>
    sharedNavItem({
      $isActive,
      $isMobileOnly,
      $isExpanded,
    })}
`

export const StyledChevronDownIcon = styled(ChevronDownIcon)`
  /* left blank intentionally so that icon can be controlled in css below */
`

export const LanguageNavHover = styled.div`
  ${({ theme }) => css`
    align-items: baseline;
    display: flex;

    > ${ButtonNavItem}, > ${LinkNavItem} {
      padding-right: 0;
    }

    &:hover {
      > ${ButtonNavItem}, > ${LinkNavItem} {
        color: ${theme.colours.primary.primary};
      }

      ${StyledChevronDownIcon} {
        transform: rotate(-180deg);
      }

      ${StyledLanguageNav} {
        display: flex;
      }
    }
  `}
`

export const StyledNavButton = styled(ButtonNavItem)`
  cursor: pointer;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colours.grey[45]};
  background: transparent;

  ${({ theme }) => theme.media.up.xl} {
    background: ${({ theme }) => theme.colours.primary.white};
    border-bottom: 0;
  }
`

export const StyledLanguageNav = styled.nav<{ $visible?: boolean }>`
  ${({ theme, $visible }) => css`
    display: ${$visible ? 'flex' : 'none'};
    flex-direction: column;

    ${theme.media.up.xl} {
      background: ${theme.colours.primary.white};
      box-shadow: ${theme.shadows.small};
      padding: 10px;
      position: absolute;
      right: 0;
      top: 100%;
      width: 200px;
      z-index: ${theme.zIndex.headerLanguageDropdown};

      ${ButtonNavItem},
      ${LinkNavItem} {
        padding: 10px 0;
      }
    }
  `}
`

export const LinksWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 115px;
    }

    ${LinkNavItem},
    ${ButtonNavItem} {
      width: 100%;
    }

    ${theme.media.up.xl} {
      display: flex;
      flex-direction: row;
      margin-top: 1px;
      width: auto;

      &::after {
        display: none;
      }

      &::before {
        background-color: ${theme.colours.grey[45]};
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 15px;
        top: 0;
      }
    }
  `}
`
