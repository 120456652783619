import { Id, Slide, ToastContainer, ToastOptions, toast } from 'react-toastify'
import { createGlobalStyle, css } from 'styled-components'

import { CloseIcon } from '@nx/fire/assets'

import {
  StyledBody,
  StyledCloseButton,
  StyledErrorIcon,
  StyledInfoIcon,
  StyledSuccessIcon,
  StyledWarningIcon,
} from './fire-toast.styles'

// GlobalStyle cannot be snapshot tested https://github.com/masakudamatsu/nextjs-template/issues/17
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    body {
      .Toastify__toast-container {
        ${theme.media.down.md} {
          width: 100vw;
          padding: 0;
          left: 0;
          margin: 0;
          bottom: 0;
          transform: none;
        }

        &:has(> .notification-full-width) {
          width: 400px;
        }
      }

      .Toastify__toast-container--top-left,
      .Toastify__toast-container--top-center,
      .Toastify__toast-container--top-right {
        ${theme.media.down.md} {
          top: 0;
          transform: translateX(0);
        }
      }

      .Toastify__toast {
        border-radius: 2px;
        box-shadow: ${theme.shadows.large};
        margin-bottom: 0;
        min-height: auto;
        padding-right: 42px;

        ${theme.media.up.md} {
          margin-bottom: 15px;
        }
      }

      .Toastify__toast-icon {
        width: 25px;
      }

      .Toastify .notification-full-width {
        padding: 0;
        margin: 0;

        .Toastify__toast-body {
          padding: 0;
        }
      }
    }
  `}
`

export function ToastSetup() {
  return (
    <>
      {/**
       * Obviously importing a third party library like this sucks, but unfortunately due to a bug with NextJS 13 and NX 15
       * as detailed here https://github.com/nrwl/nx/issues/14015 we cannot do a regular import of a css file within a lib.
       * The correct way to import this css would be to import it from node_modules like so: import 'swiper/swiper-bundle.css'
       *
       * TODO: Once the above bug is fixed, import the css properly
       */}
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/react-toastify@9.1.1/dist/ReactToastify.min.css"
      />
      <GlobalStyle />

      <ToastContainer
        position="top-right"
        transition={Slide}
        hideProgressBar
        closeOnClick={false}
        autoClose={4000}
        closeButton={({ closeToast }) => (
          <StyledCloseButton onClick={closeToast}>
            <CloseIcon title="Close" />
          </StyledCloseButton>
        )}
        role="status"
      />
    </>
  )
}

// Toastify adds its own theme so to prevent clashes we have to create icons like so
const SuccessIcon = () => <StyledSuccessIcon title="success icon" />
const InfoIcon = () => <StyledInfoIcon title="info icon" />
const WarningIcon = () => <StyledWarningIcon title="warning icon" />
const ErrorIcon = () => <StyledErrorIcon title="error icon" />

export const showToast = {
  success: (message: React.ReactNode, options?: ToastOptions) =>
    toast.success(() => <StyledBody>{message}</StyledBody>, {
      icon: <SuccessIcon />,
      ...options,
    }),
  info: (message: React.ReactNode, options?: ToastOptions) =>
    toast.info(() => <StyledBody>{message}</StyledBody>, {
      icon: <InfoIcon />,
      ...options,
    }),
  warning: (message: React.ReactNode, options?: ToastOptions) =>
    toast.warning(() => <StyledBody>{message}</StyledBody>, {
      icon: <WarningIcon />,
      ...options,
    }),
  error: (message: React.ReactNode, options?: ToastOptions) =>
    toast.error(() => <StyledBody>{message}</StyledBody>, {
      icon: <ErrorIcon />,
      ...options,
    }),
  dismiss: (id: Id | null) => id && toast.dismiss(id),
}
