import getConfig from 'next/config'

import { MaintenanceMode } from '@nx/global-types'
import { getTranslate } from '@nx/translations'

import { MaintenanceText, MaintenanceWrapper } from './MaintenanceStatus.styles'
import translate from './MaintenanceStatus.translate.json'

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

export function MaintenanceStatus() {
  const isMaintenanceMode =
    publicRuntimeConfig.MAINTENANCE_MODE === MaintenanceMode.partial

  return isMaintenanceMode ? (
    <MaintenanceWrapper>
      <MaintenanceText $level="L">{text('maintenance')}</MaintenanceText>
    </MaintenanceWrapper>
  ) : null
}
