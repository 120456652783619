import * as Sentry from '@sentry/nextjs'
import { IncomingMessage } from 'http'

import { axiosInstance } from '@nx/fetch'
import { logger } from '@nx/utils/logger'

export type ServerRequest = IncomingMessage & {
  cookies: Partial<Record<string, string>>
}

export async function amLogout(baseURL: string, req?: ServerRequest) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    ...(req?.headers?.cookie && { cookie: req.headers.cookie }),
  }

  try {
    return await axiosInstance.post(
      '/logout',
      {},
      {
        withCredentials: true,
        headers,
        baseURL,
      }
    )
  } catch (error) {
    Sentry.captureException(error)
    logger.error(
      error,
      `Something happened while logging out from Auction Mobility. Headers: ${JSON.stringify(
        headers
      )}.`
    )

    throw error
  }
}
