import React from 'react'

import { LinkNavItem } from '../../fire-header.styles'

export function AccountNav({
  links,
  closeMobileNav,
  $isMobileOnly,
}: AccountNavProps) {
  return (
    <>
      {links.map(
        ({ text, href, forceAnchor, onClick, shouldCloseMobileNav }, index) => (
          <LinkNavItem
            key={index}
            href={href}
            $isMobileOnly={$isMobileOnly}
            forceAnchor={forceAnchor}
            onClick={(e: React.SyntheticEvent) => {
              onClick?.(e)
              shouldCloseMobileNav && closeMobileNav()
            }}
          >
            {text}
          </LinkNavItem>
        )
      )}
    </>
  )
}

interface AccountNavProps {
  links: {
    text: React.ReactNode
    href: string
    forceAnchor?: boolean
    onClick?: (e: React.SyntheticEvent) => void
    shouldCloseMobileNav?: boolean
  }[]
  closeMobileNav: () => void
  $isMobileOnly?: boolean
}
