import { IncomingMessage, ServerResponse } from 'http'
import jsCookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'

import { OneTrustGroup } from '@nx/global-types'
import { getDomainNameFromHost } from '@nx/helpers'
import { isOneTrustCookieGroupEnabled } from '@nx/one-trust'

const BONHAMS_ACCESS = 'bonhams_access'
const BONHAMS_TOKEN = 'bonhams_token'
const BONHAMS_SID = 'bonhams_sid'
const SESSIONID = 'sessionid'

function getClientHost() {
  return typeof window !== 'undefined'
    ? window.location.host
    : 'www.bonhams.com'
}

interface SetAuthCookiesProps {
  accessToken: string
  refreshToken: string
}

export function setAuthCookies({
  accessToken,
  refreshToken,
}: SetAuthCookiesProps) {
  const cookieOptions = {
    domain: getDomainNameFromHost(getClientHost()),
    path: '/',
    secure: false,
  }

  const accessExpiry = jwtDecode<{ exp: number }>(accessToken).exp
  jsCookies.set(BONHAMS_ACCESS, accessToken, {
    ...cookieOptions,
    expires: new Date(accessExpiry * 1000),
  })

  const refreshExpiry = jwtDecode<{ exp: number }>(refreshToken).exp
  jsCookies.set(BONHAMS_TOKEN, refreshToken, {
    ...cookieOptions,
    expires: new Date(refreshExpiry * 1000),
  })
}

interface SetSSRAuthCookiesProps {
  accessToken: string
  refreshToken: string
  req: IncomingMessage
  res: ServerResponse
}

export async function setSSRAuthCookies({
  accessToken,
  refreshToken,
  req,
  res,
}: SetSSRAuthCookiesProps) {
  // dynamically importing cookies to avoid an import of 'crypto' lib on the client side
  const { default: Cookies } = await import('cookies')
  const cookieJar = new Cookies(req, res)

  const cookieOptions: Parameters<typeof cookieJar.set>[2] = {
    domain: getDomainNameFromHost(req.headers.host || 'www.bonhams.com'),
    path: '/',
    secure: false,
    httpOnly: false,
  }

  const accessExpiry = jwtDecode<{ exp: number }>(accessToken).exp
  cookieJar.set(BONHAMS_ACCESS, accessToken, {
    ...cookieOptions,
    expires: new Date(accessExpiry * 1000),
  })
  const refreshExpiry = jwtDecode<{ exp: number }>(refreshToken).exp
  cookieJar.set(BONHAMS_TOKEN, refreshToken, {
    ...cookieOptions,
    expires: new Date(refreshExpiry * 1000),
  })
}

export function setSessionCookie(sessionToken: string) {
  const host = getClientHost()

  const isTargetingCookieGroupEnabled = isOneTrustCookieGroupEnabled(
    OneTrustGroup.Targeting,
    jsCookies.get()
  )

  isTargetingCookieGroupEnabled &&
    jsCookies.set(BONHAMS_SID, sessionToken, {
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
      secure: true,
      domain: getDomainNameFromHost(host),
      path: '/',
      sameSite: 'strict',
    })
}

export function getCrossingMindsSessionCookie() {
  return jsCookies.get('xm_id')
}

export function removeAuthCookies() {
  const host = getClientHost()

  jsCookies.remove(BONHAMS_ACCESS, {
    domain: getDomainNameFromHost(host),
    path: '/',
  })
  jsCookies.remove(BONHAMS_TOKEN, {
    domain: getDomainNameFromHost(host),
    path: '/',
  })
}

export async function removeSSRAuthCookies(
  req: IncomingMessage,
  res: ServerResponse
) {
  const cookieOptions = {
    domain: getDomainNameFromHost(req.headers.host || 'www.bonhams.com'),
    expires: new Date(Date.now()),
    path: '/',
    httpOnly: false,
  }

  const ldcCookieOpts = {
    ...cookieOptions,
    domain: undefined,
  }

  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() - 1)

  // dynamically importing cookies to avoid an import of 'crypto' lib on the client side
  const { default: Cookies } = await import('cookies')
  const cookieJar = new Cookies(req, res)

  cookieJar.set(BONHAMS_ACCESS, 'delete', cookieOptions)
  cookieJar.set(BONHAMS_TOKEN, 'delete', cookieOptions)
  cookieJar.set(SESSIONID, 'delete', ldcCookieOpts)
}
