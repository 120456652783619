import styled, { css } from 'styled-components'

export const WeChatContainerDiv = styled.div<{ $isAttachedMobile?: boolean }>`
  ${({ $isAttachedMobile, theme: { media } }) =>
    $isAttachedMobile &&
    `
      ${media.down.lg} {
        position: initial;
      }

  `}

  position: relative;
`

export const WeChatButton = styled.button`
  color: inherit;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0;
`

export const WeChatQrDiv = styled.div<{ $isAttachedMobile?: boolean }>`
  position: absolute;
  min-height: 170px;
  right: 10px;
  max-width: 180px;
  top: -190px;
  background: ${({ theme: { colours } }) => colours.primary.white};
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 180px;
  z-index: ${({ theme }) => theme.zIndex.popup};

  ${({ theme: { media } }) => media.up.xl} {
    height: 100%;
    right: -10px;
  }

  &[data-active='false'] {
    top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    bottom: -16px;
    right: 7px;
    z-index: ${({ theme }) => theme.zIndex.popup};
    box-sizing: border-box;
    border: 8px solid ${({ theme }) => theme.colours.primary.white};
    border-color: ${({ theme }) =>
      css`rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) ${theme.colours.primary.white} ${theme.colours.primary.white}`};
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: 0 1px 10px -5px #00000066;

    ${({ theme: { media } }) => media.up.xl} {
      right: 15px;
    }
  }

  ${({ $isAttachedMobile, theme: { media } }) =>
    $isAttachedMobile &&
    `
      ${media.down.lg} {
        left: 50vw;
        transform: translateX(-50%);
        margin-top: 10px;
        top: initial;

        &::after {
          content: none;
        }
      }
  `}
`

export const WeChatQrImg = styled.img`
  height: 85%;
  object-fit: contain;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
`

export const WeChatQrCloseDiv = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  cursor: pointer;
  text-align: right;
  color: ${({ theme }) => theme.colours.primary.primary};

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
    z-index: ${({ theme }) => theme.zIndex.popup};
  }

  svg {
    width: 10px;
  }
`

export const WeChatSpan = styled.span`
  ${({ theme }) => theme.typography.body.M};

  margin-left: 2px;
`
