import { AxiosRequestConfigWithRetry, axiosInstance } from '@nx/fetch'

import { CurrencyRateResponse } from '../types'

export async function getCurrencyRates(
  baseURL: string,
  options?: AxiosRequestConfigWithRetry
) {
  return await axiosInstance
    .get<CurrencyRateResponse>(`/api/geoip/v1/currency-rates/`, {
      baseURL,
      ...options,
    })
    .then((response) => response.data)
}
