import { BonhamsCookies } from '@nx/global-types'
import { UserPersona } from './enums'

export function getUserPersona(cookies: BonhamsCookies): UserPersona {
  let userPersona = UserPersona.Guest

  if (cookies?.bonhams_sid) {
    userPersona = UserPersona.Known

    if (cookies.bonhams_token) {
      userPersona = UserPersona.LoggedIn
    }
  }

  return userPersona
}
