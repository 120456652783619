import { axiosInstance } from '@nx/fetch'

import { CheckUserLoginStatus, CheckUserLoginStatusReturn } from '../types/'

export async function checkUserLoginStatus({
  tokenCookie,
  apiUrl,
}: CheckUserLoginStatus): Promise<CheckUserLoginStatusReturn> {
  if (!tokenCookie) {
    return { isLoggedIn: false, user: { iClientNo: 0 } }
  }

  return axiosInstance
    .post(
      '/api/client/token/',
      { token: tokenCookie },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        baseURL: apiUrl,
      }
    )
    .then((response) => ({
      isLoggedIn: true,
      user: response.data,
    }))
    .catch(() => ({ isLoggedIn: false, user: { iClientNo: 0 } }))
}
