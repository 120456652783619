import Image from 'next/image'
import styled, { css } from 'styled-components'

import { LogoLink } from '@nx/fire/header'
import { Body } from '@nx/fire/typography'

export const SkinnerLogoLink = styled(LogoLink)`
  ${({ theme }) => css`
    flex-basis: 124px;

    ${theme.media.up.xl} {
      flex-basis: 144px;
      height: 22px;
    }
  `}
`

export const CornetteLogoLink = styled(LogoLink)`
  ${({ theme }) => css`
    flex-basis: 218px;

    ${theme.media.up.xl} {
      flex-basis: 245px;
      height: 18px;
    }
  `}
`

export const StyledSelectedCurrency = styled.div`
  display: flex;
  align-items: center;

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colours.primary.primary};
  }
`

export const StyledCurrencyISO = styled(Body)`
  ${({ theme }) => css`
    margin: 0;
    padding-left: 5px;
    line-height: 1em;
    color: inherit;

    ${theme.media.up.xl} {
      font-weight: 700;
    }
  `}
`

export const StyledFlagIcon = styled(Image)`
  border-radius: 50%;
`
