import { axiosInstance } from '@nx/fetch'

import { GeoIPRegionResponse } from './region.types'

export async function getRegion(baseURL: string, ipAddress: string) {
  if (!ipAddress) return null

  try {
    const response = await axiosInstance.get<GeoIPRegionResponse>(
      '/api/geoip/v1/region/',
      {
        baseURL,
        params: {
          ip: ipAddress,
        },
      }
    )

    return response.data
  } catch {
    return null
  }
}
