import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { DepartmentResponse, getDepartments } from '@nx/api'
import { populateUrl } from '@nx/populate-url'

export const useGetInterests = (baseUrl: string) => {
  const [isEnabled, setIsEnabled] = useState(false)

  const {
    data: departments,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['interests'],
    queryFn: async () => {
      const response = await getDepartments(baseUrl, {})

      if (response === null) {
        throw new Error('Error getting departments')
      }
      return response
    },
    enabled: isEnabled,
  })

  const sortDepartment = (a: DepartmentResponse, b: DepartmentResponse) =>
    a.departmentName > b.departmentName ? 1 : -1

  const categoryDepartments = departments
    ? departments
        .filter((department) => !!department.childDepartments?.length)
        .sort(sortDepartment)
    : []
  const otherDepartments = departments
    ? departments
        .filter((department) => !department.childDepartments?.length)
        .sort(sortDepartment)
    : []

  return {
    data: [...categoryDepartments, ...otherDepartments].map(
      ({ department, departmentName, interestImage }) => ({
        id: department,
        name: departmentName,
        imageUrl: interestImage?.url
          ? populateUrl(interestImage.url, interestImage.URLParams, {
              width: 180,
            })
          : '',
      })
    ),
    isError,
    isFetching,
    enableFetch: () => setIsEnabled(true),
  }
}
