import styled, { css } from 'styled-components'

import { FireButton } from '@nx/fire/button'
import {
  FireModal,
  StyledCloseButton,
  StyledCloseButtonContainer,
  StyledHeader,
  StyledHeading,
} from '@nx/fire/modal'
import { Body } from '@nx/fire/typography'

import createAccountLandscape from './assets/create-account-landscape.jpg'
import createAccount from './assets/create-account.jpg'
import { Loading } from './loading'
import { ModalScreen } from './types'

export const StyledFireModal = styled(FireModal)<{ $screen: ModalScreen }>`
  ${({ $screen }) =>
    ($screen === 'CREATE-ACCOUNT' || $screen === 'CREATE-BUSINESS-ACCOUNT') &&
    css`
      ${StyledCloseButtonContainer} {
        background: transparent;
      }

      ${({ theme }) => `${theme.media.up.lg} and (min-height: 1024px)`} {
        ${StyledHeader} {
          background-color: ${({ theme }) => theme.colours.primary.white};
          background-image: url(${createAccountLandscape}),
            linear-gradient(
              to bottom,
              ${({ theme }) => theme.colours.grey['45']},
              ${({ theme }) => theme.colours.grey['45']} 210px,
              transparent 210px
            );
          background-size: contain;
          background-repeat: no-repeat;
          min-height: 210px;
          margin-left: -30px;
          margin-right: -30px;
          padding-left: 30px;
          padding-top: 210px;
        }

        ${StyledCloseButtonContainer} {
          right: 10px !important;
        }
      }

      ${({ theme }) => theme.media.up.xl} {
        min-width: 900px;
        background-color: ${({ theme }) => theme.colours.primary.white};
        background-image: url(${createAccount}),
          linear-gradient(
            to right,
            ${({ theme }) => theme.colours.grey['45']},
            ${({ theme }) => theme.colours.grey['45']}
          );
        background-size: 45%;
        background-position: top right;
        background-repeat: no-repeat;
        max-height: min(736px, 80vh);

        ${StyledHeader} {
          background: transparent;
          min-height: auto;
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-top: 0;
        }

        ${StyledHeading} {
          width: 55%;
          background: ${({ theme }) => theme.colours.primary.white};
        }

        ${StyledCloseButtonContainer} {
          right: -17px !important;
        }
      }
    `}

  ${({ $screen }) =>
    $screen === 'SELECT-INTERESTS' &&
    css`
      ${StyledHeader} {
        background: transparent;
      }

      ${StyledCloseButton} {
        z-index: ${({ theme }) => theme.zIndex.modal + 1};
      }

      ${({ theme }) => theme.media.up.lg} {
        padding-bottom: 0;
        height: 80vh;
      }

      ${({ theme }) => theme.media.up.xl} {
        min-width: 900px;
      }
    `}
`

export const StyledForm = styled.form`
  margin-top: 20px;
  ${({ theme }) => theme.helpers.topSeparationLine}
  padding-top: 31px;
  position: relative;
  z-index: -1;
`

export const StyledRow = styled.div`
  &:not(:first-of-type) {
    margin-top: 22px;
  }
`

export const StyledActionButtons = styled.div`
  position: fixed;
  bottom: 10px;
  width: calc(100% - 20px);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 10px;
  align-items: center;

  ${({ theme }) => theme.media.up.lg} {
    position: static;
    bottom: 0;
    margin: 122px auto 0;
    width: fit-content;
    gap: 50px;
  }
`

export const StyledFireButton = styled(FireButton)`
  white-space: nowrap;

  ${({ theme }) => theme.media.up.lg} {
    min-width: 150px;
    width: fit-content;
  }
`

export const StyledErrorMessage = styled(Body)`
  color: ${({ theme }) => theme.colours.status.error};
  padding: 0 0 16px;
`

export const StyledCreateAccountLoading = styled(Loading)`
  height: 599px;

  ${/* istanbul ignore next */ ({ theme }) => theme.media.up.xl} {
    height: 652px;
    width: 50%;
  }
`

export const StyledLoginLoading = styled(Loading)`
  height: 434px;
`

export const StyledForgotPasswordLoading = styled(Loading)`
  height: 372px;
`

export const StyledForgottenPasswordLoading = styled(Loading)`
  height: 282px;
`
