import dynamic from 'next/dynamic'
import { useState } from 'react'

import { WeChatIcon } from '@nx/fire/assets'
import { getTranslate } from '@nx/translations'

import {
  WeChatButton,
  WeChatContainerDiv,
  WeChatSpan,
} from './fire-we-chat-popup.styles'
import translate from './fire-we-chat-popup.translate.json'

const WeChatBubble = dynamic(() => import('./fire-we-chat-popup-bubble'))

const { text } = getTranslate(translate)

export function FireWeChatPopup({
  isAttachedMobile,
  isOnlyIcon,
  qrCodeImg,
}: FireWeChatPopupProps) {
  const [showQR, setShowQR] = useState(false)

  function toggleQR() {
    setShowQR((previous) => !previous)
  }

  return (
    <WeChatContainerDiv $isAttachedMobile={isAttachedMobile}>
      <WeChatButton data-testid="weChat" onClick={toggleQR}>
        <WeChatIcon
          width="22"
          height="22"
          title={isOnlyIcon ? text('wechat') : undefined}
        />
        {!isOnlyIcon && (
          <WeChatSpan data-testid="weChat-label">{text('wechat')}</WeChatSpan>
        )}
      </WeChatButton>
      {showQR && (
        <WeChatBubble
          isAttachedMobile={isAttachedMobile}
          toggleQR={toggleQR}
          qrCodeImg={qrCodeImg}
        />
      )}
    </WeChatContainerDiv>
  )
}

export interface FireWeChatPopupProps {
  isAttachedMobile?: boolean
  isOnlyIcon?: boolean
  qrCodeImg?: string
}

export default FireWeChatPopup
