import Cookies from 'js-cookie'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react'

import { getDomainNameFromHost } from '@nx/helpers'

import { CURRENCY_CONFIG_COOKIE } from '@web/helpers/enums'
import { getClientHost } from '@web/helpers/utils'

export const UserContext = createContext<UserInfoContext>({
  currencyConfig: undefined,
})

export interface CurrencyConfig {
  userRegionCurrency: string
  userSelectedCurrency: string
  userLocaleCurrency: string
}

export interface UserInfoContext {
  currencyConfig: CurrencyConfig | undefined
  setCurrencyConfig?: Dispatch<SetStateAction<CurrencyConfig | undefined>>
}

interface UserContextProviderProps {
  value?: UserInfoContext
  children: React.ReactNode
}

export function UserContextProvider({
  value,
  children,
}: UserContextProviderProps) {
  const [currencyConfig, setCurrencyConfig] = useState(value?.currencyConfig)

  useEffect(() => {
    if (currencyConfig) {
      const existingCookieValue = Cookies.get(CURRENCY_CONFIG_COOKIE)
      if (existingCookieValue !== JSON.stringify(currencyConfig)) {
        Cookies.set(CURRENCY_CONFIG_COOKIE, JSON.stringify(currencyConfig), {
          path: '/',
          httpOnly: false,
          expires: 365, // 1 year
          domain: getDomainNameFromHost(getClientHost()),
        })
      }
    }
  }, [currencyConfig])

  return (
    <UserContext.Provider value={{ currencyConfig, setCurrencyConfig }}>
      {children}
    </UserContext.Provider>
  )
}
