import { IncomingMessage, ServerResponse } from 'http'
import * as cookie from 'cookie'

export const parseCookieRequestHeader = cookie.parse

export function getCookiesFromApp(res: ServerResponse<IncomingMessage>) {
  const setCookieFromApp = res.getHeader('Set-Cookie')

  if (!setCookieFromApp) return {}

  const cookieItems = Array.isArray(setCookieFromApp)
    ? setCookieFromApp
    : [String(setCookieFromApp)]

  return cookieItems.reduce((obj, cur) => {
    const [name, value] = cur.split('=')
    obj[name] = value.split(';')[0]

    return obj
  }, {} as Record<string, string>)
}
