import styled, { css } from 'styled-components'

import { FireButton, FireButtonProps } from '@nx/fire/button'
import { FireLink, FireLinkProps } from '@nx/fire/link'
import {
  Body,
  BodyProps,
  Heading,
  HeadingProps,
  Label,
} from '@nx/fire/typography'

export const Footer = styled.footer.attrs({
  className: 'footer',
})`
  ${({ theme }) => css`
    background-color: ${theme.colours.background.main};
    padding: 40px 0 60px;
    position: relative;
    box-shadow: ${theme.shadows.small};
  `}
`

export const FooterContainerDiv = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 0 auto;
    max-width: 1190px;
    padding: 0 15px;
    width: 100%;

    ${theme.media.up.xl} {
      flex-direction: row;
    }
  `}
`

export const FooterContainerLeft = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    gap: 30px;

    ${theme.media.up.lg} {
      flex-basis: 66%;
      gap: 40px;
    }
  `}
`

export const FooterContainerRight = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    gap: 30px;

    ${theme.media.up.lg} {
      flex-basis: 33%;
      gap: 40px;
    }
  `}
`

const BrandLogoWidth: Record<string, string> = {
  skinner: '200px',
  cornette: '200px',
  bonhams: '160px',
  'bonhams-cars': '265px',
}

export const FooterLogoImg = styled.svg`
  ${({ theme }) => css`
    color: ${theme.colours.primary.black};
    width: ${BrandLogoWidth[theme.name]};

    ${theme.media.up.lg} {
      margin-right: ${theme.name === 'skinner' ? '25px' : 0};
    }

    + svg {
      margin-left: 10px;
    }
  `};
`

export const FooterLinksDiv = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`

export const FooterLinksItemDiv = styled.div`
  ${({ theme }) => css`
    align-self: flex-start;
    margin-bottom: 25px;
    max-width: 50%;
    flex-basis: 50%;
    padding-right: 10px;

    &:nth-child(2n) {
      padding-right: 0;
    }

    ${theme.media.up.lg} {
      max-width: 33.33%;
      flex-basis: 33.33%;

      &:nth-child(2n) {
        padding-right: 10px;
      }

      &:nth-child(3n) {
        padding-right: 0;
      }
    }
  `};
`

export const FooterLink = styled(FireLink)<FireLinkProps>`
  ${({ theme }) => css`
    color: ${theme.colours.primary.black};
  `};
`

export const FooterButton = styled(FireButton)<FireButtonProps>`
  ${({ theme }) => css`
    color: ${theme.colours.primary.black};

    &:hover:enabled,
    &:focus:enabled {
      color: ${theme.colours.primary.black};
    }
  `};
`

export const FooterCopyright = styled(Body)<BodyProps>`
  text-align: right;
`

export const SocialDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const socialButtonStyle = css`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colours.primary.black};
    display: flex;
    height: 42px;
    justify-content: center;
    margin-right: 20px;
    padding: 6px;
    width: 42px;

    &:last-child {
      margin-right: 0;
    }

    * {
      height: 100%;
      width: 100%;
    }

    ${theme.media.up.xl} {
      margin-right: 30px;
    }
  `}
`

export const SocialItemDiv = styled.div`
  ${socialButtonStyle}
`

export const FooterSocialLink = styled(FireLink)<FireLinkProps>`
  ${socialButtonStyle}
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const FormInputWrapper = styled.div`
  display: flex;
  width: 100%;

  & > div {
    flex-grow: 1;
    margin-right: 10px;
  }

  & > button {
    margin-top: 4px;
  }
`

export const StyledHeading = styled(Heading)<HeadingProps>`
  margin-bottom: 10px;
`

export const NewsletterFormErrorText = styled(Label)<BodyProps>`
  color: ${({ theme }) => theme.colours.status.error};
  margin-bottom: 10px;
`

export const DisclaimerText = styled(Body)<BodyProps>`
  color: ${({ theme }) => theme.colours.grey['75']};
  margin-top: 5px;
`
