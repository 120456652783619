import axios, { AxiosError, AxiosInstance } from 'axios'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'

import { BonhamsCookies } from '@nx/global-types'
import { isServer } from '@nx/helpers'
import { removeAuthCookies } from '@nx/user-auth-cookies'

import { rejectAxiosResponse } from './rejectAxiosResponse'
import { requestTokenRefresh } from './requestTokenRefresh'

export function createAxiosClient({
  refreshTokenSSR,
  accessTokenSSR,
  baseURL,
  timeout = 5000,
  isWithInterceptor = true,
}: {
  refreshTokenSSR?: BonhamsCookies['bonhams_token']
  accessTokenSSR?: BonhamsCookies['bonhams_access']
  baseURL?: string
  timeout?: number
  isWithInterceptor?: boolean
}) {
  const authCookies: BonhamsCookies = Cookies.get()
  const accessToken = isServer() ? accessTokenSSR : authCookies.bonhams_access

  const authenticatedAxiosClient = axios.create({
    timeout,
    baseURL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (isWithInterceptor) {
    authenticatedAxiosClient.interceptors.response.use(
      (response) => response,
      onRejected({ authenticatedAxiosClient, refreshTokenSSR, authCookies })
    )
  }

  return authenticatedAxiosClient
}

const onRejected =
  ({
    authenticatedAxiosClient,
    refreshTokenSSR,
    authCookies,
  }: {
    authenticatedAxiosClient: AxiosInstance
    refreshTokenSSR?: string
    authCookies: BonhamsCookies
  }) =>
  async (error: AxiosError | Error) => {
    /* istanbul ignore else */
    if (axios.isAxiosError(error)) {
      /* istanbul ignore next */
      if (typeof error.config === 'undefined') {
        return rejectAxiosResponse(error)
      }

      const originalRequest = error.config

      if (
        error.response &&
        error.response.status === 401 &&
        originalRequest.url === '/api/token/refresh/'
      ) {
        return rejectAxiosResponse(error)
      }

      if (error.response?.data && error.response.status === 401) {
        const refreshToken = isServer()
          ? refreshTokenSSR
          : authCookies.bonhams_token

        if (refreshToken) {
          let tokenPartsRefresh: { exp: number } | null = null

          try {
            tokenPartsRefresh = jwtDecode<{ exp: number }>(refreshToken)
          } catch (error) {
            removeAuthCookies()

            return Promise.reject({
              status: 500,
              message: 'Auth token is malformed',
              error,
            })
          }

          const now = Math.ceil(Date.now() / 1000)

          if (tokenPartsRefresh && tokenPartsRefresh.exp > now) {
            return requestTokenRefresh({
              authenticatedAxiosClient,
              originalRequest,
              refreshToken,
            })
          } else {
            removeAuthCookies()

            return rejectAxiosResponse(error)
          }
        } else {
          removeAuthCookies()

          return rejectAxiosResponse(error)
        }
      } else {
        return rejectAxiosResponse(error)
      }
    } else {
      return Promise.reject({
        status: 500,
        message: error.message,
        error,
      })
    }
  }
