import { QueryClient } from '@tanstack/react-query'
import { NextRouter } from 'next/router'

import { axiosInstance } from '@nx/fetch'

export const logout = async ({
  baseURL,
  queryClient,
  router,
}: LogoutParams) => {
  axiosInstance
    .get('/api/auth/logout/', {
      baseURL,
    })
    .then(async () => {
      await Promise.allSettled(
        [
          'user-login-status',
          'user',
          'followed-lots',
          'followed-departments',
          'followed-sales',
          'followed-artists',
          'interests',
        ].map((key) => queryClient.resetQueries({ queryKey: [key] }))
      )

      if (router.pathname.includes('mybonhams')) {
        await router.push('/')
      }
    })
    .catch(async () => {
      await router.push('/logout/')
    })
}

interface LogoutParams {
  baseURL: string
  queryClient: QueryClient
  router: NextRouter
}
