'use client'

import { useEffect } from 'react'

import { useDataLayer } from './useDataLayer'

export const useUserIdTracking = (userId: number) => {
  const dataLayer = useDataLayer()

  useEffect(() => {
    if (!userId) return

    dataLayer.push({
      userId,
    })
  }, [dataLayer, userId])
}
