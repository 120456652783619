import getConfig from 'next/config'
import { useContext, useEffect } from 'react'

import { useGetRegion } from '@nx/api/hooks'

import { CurrencyConfig, UserContext } from '@web/context/UserInfoProvider'

const { publicRuntimeConfig } = getConfig()

interface UseCurrencyConversionProps {
  userIP: string
  currencyConfig?: CurrencyConfig
}

export function useCurrencyConversion({
  userIP,
  currencyConfig,
}: UseCurrencyConversionProps) {
  const userContext = useContext(UserContext)

  const { isSuccess, data: region } = useGetRegion(
    publicRuntimeConfig.WEB_API_URL,
    userIP,
    !currencyConfig?.userRegionCurrency || !currencyConfig?.userLocaleCurrency
  )

  const isoCurrencyCode = region?.country?.currency?.iso_code
  const isoLocale = region?.country?.locale.replace('_', '-')

  useEffect(() => {
    if (
      !isSuccess ||
      !isoCurrencyCode ||
      !isoLocale ||
      (userContext.currencyConfig?.userRegionCurrency === isoCurrencyCode &&
        userContext.currencyConfig?.userLocaleCurrency === isoLocale)
    ) {
      return
    }

    userContext.setCurrencyConfig?.({
      userRegionCurrency: isoCurrencyCode,
      userLocaleCurrency: isoLocale,
      userSelectedCurrency: isoCurrencyCode,
    })
  }, [isSuccess, isoCurrencyCode, isoLocale, userContext])
}
