import dynamic from 'next/dynamic'
import { ParsedUrlQuery } from 'querystring'
import { useCallback, useEffect, useState } from 'react'

import { FireModalProps } from '@nx/fire/modal'
import { MaintenanceMode } from '@nx/global-types'
import { useUserAuth } from '@nx/hooks/use-user-auth'
import { getTranslate } from '@nx/translations'

import {
  closeLogin,
  closeLoginEventName,
  getRedirectUrl,
} from './close-login.helper'
import {
  StyledCreateAccountLoading,
  StyledFireModal,
  StyledForgotPasswordLoading,
  StyledForgottenPasswordLoading,
  StyledLoginLoading,
} from './fire-login-modal.styles'
import translate from './fire-login-modal.translate.json'
import { ExtraErrorMessagesProps, Interest, ModalScreen } from './types'

const LoginScreenDynamic = dynamic(
  () => import('./screens').then(({ LoginScreen }) => LoginScreen),
  {
    ssr: false,
    loading: StyledLoginLoading,
  }
)

const CreateAccountScreenDynamic = dynamic(
  () =>
    import('./screens').then(({ CreateAccountScreen }) => CreateAccountScreen),
  {
    ssr: false,
    loading: StyledCreateAccountLoading,
  }
)

const ForgotPasswordScreenDynamic = dynamic(
  () =>
    import('./screens').then(
      ({ ForgotPasswordScreen }) => ForgotPasswordScreen
    ),
  {
    ssr: false,
    loading: StyledForgotPasswordLoading,
  }
)

const ForgottenPasswordScreenDynamic = dynamic(
  () =>
    import('./screens').then(
      ({ ForgottenPasswordScreen }) => ForgottenPasswordScreen
    ),
  {
    ssr: false,
    loading: StyledForgottenPasswordLoading,
  }
)

const SelectInterestsScreenDynamic = dynamic(
  () =>
    import('./screens').then(
      ({ SelectInterestsScreen }) => SelectInterestsScreen
    ),
  {
    ssr: false,
  }
)

const CompletePasswordResetScreenDynamic = dynamic(
  () =>
    import('./screens').then(
      ({ CompletePasswordResetScreen }) => CompletePasswordResetScreen
    ),
  {
    ssr: false,
  }
)

const PasswordConfirmScreenDynamic = dynamic(
  () =>
    import('./screens').then(
      ({ PasswordConfirmScreen }) => PasswordConfirmScreen
    ),
  {
    ssr: false,
  }
)

const openLoginEventName = 'OPEN_LOGIN'
const openForgotPasswordEventName = 'OPEN_FORGOT_PASSWORD'
const openCompletePasswordResetEventName = 'OPEN_COMPLETE_PASSWORD_RESET'
const openCreateAccountEventName = 'OPEN_CREATE_ACCOUNT'
const openCreateBusinessAccountEventName = 'OPEN_CREATE_BUSINESS_ACCOUNT'

const { text } = getTranslate(translate)

export function FireLoginModal({
  login,
  resetPassword,
  completePasswordReset,
  createAccount,
  selectInterests,
  isBusinessAccount,
  defaultScreen = 'LOGIN',
  interests,
  hasLoginCancelButton = true,
  closeCallback,
  extraErrorMessages,
  maintenanceMode,
  newPasswordFlowEnabled,
  ...fireModalProps
}: FireLoginModalProps) {
  const [screen, setScreen] = useState<ModalScreen>(defaultScreen)
  const [redirectUrl, setRedirectUrl] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const maintenanceModeEnabled = maintenanceMode === MaintenanceMode.partial

  const reset = useCallback(
    () =>
      [
        login,
        resetPassword,
        completePasswordReset,
        createAccount,
        selectInterests,
      ].map((obj) => obj.reset()),
    [
      login,
      resetPassword,
      completePasswordReset,
      createAccount,
      selectInterests,
    ]
  )

  const saveAndClose = () => {
    reset()
    closeLogin(closeCallback)
  }

  useEffect(() => {
    const handleOpenLogin = ((
      e: CustomEvent<{ screen: ModalScreen; redirectUrl?: string }>
    ) => {
      setIsVisible(true)
      setScreen(e.detail.screen)
      setRedirectUrl(e.detail.redirectUrl || '')
    }) as EventListener

    const handleCloseLogin = () => setIsVisible(false)

    window.addEventListener(openLoginEventName, handleOpenLogin)
    window.addEventListener(openForgotPasswordEventName, handleOpenLogin)
    window.addEventListener(openCompletePasswordResetEventName, handleOpenLogin)
    window.addEventListener(openCreateAccountEventName, handleOpenLogin)
    window.addEventListener(openCreateBusinessAccountEventName, handleOpenLogin)
    window.addEventListener(closeLoginEventName, handleCloseLogin)

    return () => {
      window.removeEventListener(openLoginEventName, handleOpenLogin)
      window.removeEventListener(openForgotPasswordEventName, handleOpenLogin)
      window.removeEventListener(
        openCompletePasswordResetEventName,
        handleOpenLogin
      )
      window.removeEventListener(openCreateAccountEventName, handleOpenLogin)
      window.removeEventListener(
        openCreateBusinessAccountEventName,
        handleOpenLogin
      )
      window.removeEventListener(closeLoginEventName, handleCloseLogin)
    }
  }, [])

  useEffect(() => {
    if (!isVisible) {
      setScreen(defaultScreen)
      setRedirectUrl('')
    }
  }, [isVisible, defaultScreen])

  const screens: Record<
    ModalScreen,
    { title?: string; children: React.ReactNode }
  > = {
    LOGIN: {
      title: text('form.logIn'),
      children: (
        <LoginScreenDynamic
          login={login}
          setScreen={setScreen}
          hasLoginCancelButton={hasLoginCancelButton}
          reset={reset}
          redirectUrl={redirectUrl}
          extraErrorMessages={extraErrorMessages}
        />
      ),
    },
    PASSWORD: {
      title: text('forgotPassword.title'),
      children: newPasswordFlowEnabled ? (
        <ForgottenPasswordScreenDynamic
          setScreen={setScreen}
          resetPassword={resetPassword}
          reset={reset}
        />
      ) : (
        <ForgotPasswordScreenDynamic
          setScreen={setScreen}
          resetPassword={resetPassword}
          reset={reset}
        />
      ),
    },
    'PASSWORD-CONFIRM': {
      title: text('confirmPassword.title'),
      children: (
        <PasswordConfirmScreenDynamic
          newPasswordResetFlow={newPasswordFlowEnabled}
          reset={reset}
          closeCallback={closeCallback}
        />
      ),
    },
    'COMPLETE-PASSWORD-RESET': {
      title: text('completePasswordReset.title'),
      children: (
        <CompletePasswordResetScreenDynamic
          setScreen={setScreen}
          completePasswordReset={completePasswordReset}
          reset={reset}
        />
      ),
    },
    'CREATE-ACCOUNT': {
      title: text(
        maintenanceModeEnabled
          ? 'createAccount.maintenanceModeTitle'
          : 'createAccount.title'
      ),
      children: (
        <CreateAccountScreenDynamic
          setScreen={setScreen}
          isBusinessAccount={false}
          createAccount={createAccount}
          reset={reset}
          maintenanceMode={maintenanceMode}
        />
      ),
    },
    'CREATE-BUSINESS-ACCOUNT': {
      title: text(
        maintenanceModeEnabled
          ? 'createAccount.maintenanceModeTitle'
          : 'createBusinessAccount.title'
      ),
      children: (
        <CreateAccountScreenDynamic
          setScreen={setScreen}
          isBusinessAccount={true}
          createAccount={createAccount}
          reset={reset}
          maintenanceMode={maintenanceMode}
        />
      ),
    },
    'SELECT-INTERESTS': {
      title: undefined,
      children: (
        <SelectInterestsScreenDynamic
          interests={interests}
          saveAndClose={saveAndClose}
          selectInterests={selectInterests}
        />
      ),
    },
  }

  const { title, children } = screens[screen]

  return isVisible ? (
    <StyledFireModal
      $screen={screen}
      isOpen
      setIsOpen={saveAndClose}
      heading={title}
      {...fireModalProps}
    >
      {children}
    </StyledFireModal>
  ) : null
}

export const getRedirectUrlFromParams = (query: ParsedUrlQuery) => {
  let url = '/'

  const params = ['next', 'return_to_url']

  params.forEach((param) => {
    if (query[param]) {
      url = query[param] as string
    }
  })

  return getRedirectUrl(url)
}

export function openLogin(
  screen: Exclude<
    ModalScreen,
    'PASSWORD-CONFIRM' | 'SELECT-INTERESTS'
  > = 'LOGIN',
  redirectUrl?: string
) {
  const openLoginEvent = new CustomEvent(openLoginEventName, {
    detail: { screen: 'LOGIN', redirectUrl },
  })
  const openForgotPasswordEvent = new CustomEvent(openForgotPasswordEventName, {
    detail: { screen: 'PASSWORD' },
  })

  const openCompletePasswordResetEvent = new CustomEvent(
    openCompletePasswordResetEventName,
    {
      detail: { screen: 'COMPLETE-PASSWORD-RESET' },
    }
  )
  const openCreateAccountEvent = new CustomEvent(openCreateAccountEventName, {
    detail: { screen: 'CREATE-ACCOUNT', redirectUrl },
  })
  const openCreateBusinessAccountEvent = new CustomEvent(
    openCreateBusinessAccountEventName,
    {
      detail: { screen: 'CREATE-BUSINESS-ACCOUNT', redirectUrl },
    }
  )

  const dispatchEvents: Record<
    Exclude<ModalScreen, 'PASSWORD-CONFIRM' | 'SELECT-INTERESTS'>,
    Event
  > = {
    LOGIN: openLoginEvent,
    PASSWORD: openForgotPasswordEvent,
    'COMPLETE-PASSWORD-RESET': openCompletePasswordResetEvent,
    'CREATE-ACCOUNT': openCreateAccountEvent,
    'CREATE-BUSINESS-ACCOUNT': openCreateBusinessAccountEvent,
  }

  window.dispatchEvent(dispatchEvents[screen])
}

export interface FireLoginModalProps
  extends Omit<FireModalProps, 'isOpen' | 'children' | 'setIsOpen'>,
    ReturnType<typeof useUserAuth> {
  defaultScreen?: ModalScreen
  isBusinessAccount?: boolean
  hasLoginCancelButton?: boolean
  interests: {
    data: Interest[]
    isFetching: boolean
    isError: boolean
    enableFetch: () => void
  }
  closeCallback?: () => void
  extraErrorMessages?: (props: ExtraErrorMessagesProps) => React.ReactNode
  maintenanceMode?: MaintenanceMode
  newPasswordFlowEnabled: boolean
}
