import { getTranslate } from '@nx/translations'

import { StyledSkipLink } from './fire-skip-to-content.styles'
import translate from './fire-skip-to-content.translate.json'

const { text } = getTranslate(translate)

export const SkipToContent = () => (
  <StyledSkipLink as="a" href="#skip-to-content" $variant="secondary" $size="S">
    {text('skipToContent')}
  </StyledSkipLink>
)
