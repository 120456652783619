import { isAxiosError } from 'axios'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react'

import { marketingSignup } from '@nx/api'
import { getTranslate } from '@nx/translations'
import { useDataLayer } from '@nx/utils/google-tag-manager'

import translate from './SignupModal.translate.json'
import {
  COOKIE_NAME_SUBSCRIPTION,
  EVENT_EMAIL_SIGNUP,
  EVENT_EMAIL_SIGNUP_FAIL,
} from './constants'

const { text } = getTranslate(translate)

export const useNewsletterForm = ({ apiUrl }: { apiUrl: string }) => {
  const dataLayer = useDataLayer()
  const router = useRouter()
  const [formState, setFormState] = useState<
    'IDLE' | 'SUBMITTING' | 'SUCCESS' | 'ERROR'
  >('IDLE')
  const [error, setError] = useState('')
  const [emailValue, setEmailValue] = useState('')

  useEffect(() => {
    function resetForm() {
      setError('')
      setEmailValue('')
      setFormState('IDLE')
    }

    if (formState === 'SUCCESS') {
      router.events.on('routeChangeComplete', resetForm)
    }

    return () => {
      if (formState === 'SUCCESS') {
        router.events.off('routeChangeComplete', resetForm)
      }
    }
  }, [router, formState])

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setEmailValue(e.target.value)

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    if (!emailValue) {
      setError(text('formEmailRequired'))
    } else if (/\S+@\S+\.\S+/.test(emailValue)) {
      setError('')
      submitForm()
    } else {
      setError(text('formEmailFormat'))
    }
  }

  async function submitForm() {
    setFormState('SUBMITTING')

    try {
      await marketingSignup(apiUrl, emailValue)

      const expires = new Date(new Date().getTime() + 364 * 24 * 60 * 60 * 1000)

      Cookies.set(COOKIE_NAME_SUBSCRIPTION, 'true', {
        expires,
        path: '/',
      })

      dataLayer.push({
        event: EVENT_EMAIL_SIGNUP,
      })

      setFormState('SUCCESS')
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 400) {
        dataLayer.push({
          event: EVENT_EMAIL_SIGNUP_FAIL,
          failReason: 'Invalid email address',
        })

        setError(text('formEmailFormat'))
      }

      setFormState('ERROR')
    }
  }

  return {
    handleSubmit,
    error,
    formState,
    inputProps: {
      value: emailValue,
      onChange: handleEmailChange,
    },
  }
}
