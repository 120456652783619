export type TranslateContentHtml = Record<
  string,
  string | number | React.ReactElement
>
export type TranslateContent = Record<string, string | number>
export type TranslateResultHtml =
  TranslateContentHtml[keyof TranslateContentHtml][]

export function getTranslate<T extends Record<string, string>>(translate: T) {
  function missingKeys(fileKey: keyof T, content: string) {
    let missingKeys: string[] = []
    const missingRegex = new RegExp('{([a-z0-9]+)}', 'ig')
    let ex: RegExpExecArray | null
    while ((ex = missingRegex.exec(content))) {
      missingKeys.push(ex[1])
    }
    // Remove duplicates
    missingKeys = missingKeys.filter((x, i, a) => a.indexOf(x) === i)
    if (missingKeys.length) {
      throw new Error(
        `[${String(fileKey)}] Not replaced keys: ${missingKeys.join(', ')}`
      )
    }
  }

  function notExistingKeys(fileKey: keyof T, keys: string[]) {
    const notExistingKeys = keys
      .filter(
        (key) => translate[fileKey].toString().split(`{${key}}`).length === 1
      )
      // Remove duplicates
      .filter((x, i, a) => a.indexOf(x) === i)

    if (notExistingKeys.length) {
      throw new Error(
        `[${String(fileKey)}] Not existing keys: ${notExistingKeys.join(', ')}`
      )
    }
  }

  function textHTML(fileKey: keyof T, replacements: TranslateContentHtml = {}) {
    // Check not existing keys
    notExistingKeys(fileKey, Object.keys(replacements))

    const keys = Object.keys(replacements).map((key) => `{${key}}`)
    let resultArray: TranslateResultHtml = [translate[fileKey]]
    if (keys.length) {
      const keysRegex = new RegExp(`(${keys.join('|')})`, 'g')
      resultArray = translate[fileKey].split(keysRegex)
      if (resultArray[0] === '') {
        resultArray.splice(0, 1)
      }
      if (resultArray[resultArray.length - 1] === '') {
        resultArray.splice(resultArray.length - 1, 1)
      }

      for (const [replacementKey, value] of Object.entries(replacements)) {
        const key = `{${replacementKey}}`
        resultArray = resultArray.map((r) =>
          typeof r === 'string' && r === key ? value : r
        )
      }
    }

    // Check missing keys
    missingKeys(fileKey, resultArray.join(''))
    return resultArray
  }

  function text(fileKey: keyof T, replacements: TranslateContent = {}) {
    // Check not existing keys
    notExistingKeys(fileKey, Object.keys(replacements))

    if (translate[fileKey] === undefined) {
      // eslint-disable-next-line no-console
      console.log(`[TRANSLATE] Not existing key: ${String(fileKey)}`)
      return fileKey
    }

    const result = transformText(translate[fileKey].toString(), replacements)

    // Check missing keys
    missingKeys(fileKey, result)

    return result
  }

  function plainText(fileKey: keyof T) {
    return translate[fileKey].toString()
  }

  return { plainText, text, textHTML }
}

export function transformText(
  text: string,
  replacements: TranslateContent = {}
) {
  Object.entries(replacements).forEach(([key, value]) => {
    text = text.replace(new RegExp(`{${key}}`, 'g'), value.toString())
  })

  return text
}
