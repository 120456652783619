import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'

import { setAuthCookies } from '@nx/user-auth-cookies'

export const requestTokenRefreshSuccess =
  ({
    originalRequest,
    authenticatedAxiosClient,
  }: {
    authenticatedAxiosClient: AxiosInstance
    originalRequest: InternalAxiosRequestConfig<Record<string, string> | string>
  }) =>
  (response: AxiosResponse) => {
    setAuthCookies({
      accessToken: response.data.access,
      refreshToken: response.data.refresh,
    })

    originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`

    if (originalRequest.method === 'post') {
      delete originalRequest.headers['Content-Length']

      if (typeof originalRequest.data === 'undefined') {
        originalRequest.data = '{}'
      }

      originalRequest.data = {
        ...(JSON.parse(
          /* istanbul ignore next */
          typeof originalRequest.data === 'string'
            ? originalRequest.data.trim()
            : ''
        ) as Record<string, string>),
        token: response.data.access,
      }
    }

    return authenticatedAxiosClient(originalRequest)
  }
