import { css } from 'styled-components'

import { MasterTheme, bonhamsTheme } from '@nx/fire/bonhams-theme-master'

import {
  ReactComponent as bonhamsCornetteLogoIcon,
  default as bonhamsCornetteLogoSVG,
} from './assets/bonhamsCornetteLogoSmall.svg'
import {
  ReactComponent as cornetteLogoIcon,
  default as cornetteLogoSVG,
} from './assets/cornetteLogo.svg'

export const cornetteTheme: MasterTheme = {
  ...bonhamsTheme,
  name: 'cornette',
  colours: {
    ...bonhamsTheme.colours,
    primary: {
      ...bonhamsTheme.colours.primary,
      primary: '#0c2340',
    },
  },
  logo: {
    component: cornetteLogoIcon,
    svg: cornetteLogoSVG,
  },
  footerLogo: {
    component: bonhamsCornetteLogoIcon,
    svg: bonhamsCornetteLogoSVG,
  },
  components: {
    ...bonhamsTheme.components,
    FireHeader: {
      NavItem: {
        root: css`
          &:focus,
          &:hover {
            text-decoration: underline;
          }
        `,
        active: css`
          text-decoration: underline;
        `,
      },
    },
  },
}
