import Cookies from 'js-cookie'

import {
  COOKIE_EXPIRATION,
  COOKIE_NAME_BANNER_DISMISSED,
  COOKIE_NAME_SUBSCRIPTION,
} from './constants'

export function getSubscriptionCookie() {
  return !!Cookies.get(COOKIE_NAME_SUBSCRIPTION)
}

export function getDismissCookie() {
  return !!Cookies.get(COOKIE_NAME_BANNER_DISMISSED)
}

export function setDismissCookie() {
  Cookies.set(COOKIE_NAME_BANNER_DISMISSED, 'true', {
    expires: COOKIE_EXPIRATION,
  })
}
