import { useQuery } from '@tanstack/react-query'

import { getCurrencyRates } from '@nx/api'

export const CURRENCY_RATES_STALE_TIME = 12 * 60 * 60 * 1000 //12 hours
export const CURRENCY_RATES_KEY = ['currencyRates']

export function useCurrencyRates(baseUrl: string, staleTime?: number) {
  return useQuery({
    queryKey: CURRENCY_RATES_KEY,
    queryFn: () => getCurrencyRates(baseUrl),
    staleTime: CURRENCY_RATES_STALE_TIME,
  })
}
