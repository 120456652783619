import getConfig from 'next/config'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

export const departmentRedirects: RedirectConfig[] = [
  {
    source: /department\/MOT-CAR.*/,
    destination: `${publicRuntimeConfig.CARS_BASE_URL}/`,
    permanent: true,
  },
  {
    source: /^\/departments(.*)/,
    destination: `/department{{0}}`,
    permanent: true,
  },
]
