import { useEffect, useState } from 'react'

import { currencyToFlagCode } from './currencyToFlag'
import { CurrencyRates } from './types'

interface CurrencyFlag {
  currencyCode: string
  flagCode: string
}

export function useGetFlagCodes(currencyRates: CurrencyRates | undefined) {
  const [flagCodes, setFlagCodes] = useState<CurrencyFlag[]>(
    currencyRates
      ? Object.keys(currencyRates.rates).map((currencyCode) => ({
          currencyCode,
          flagCode: currencyToFlagCode[currencyCode],
        }))
      : []
  )

  useEffect(() => {
    if (currencyRates) {
      const newFlagsCodes = Object.keys(currencyRates.rates).map(
        (currencyCode) => ({
          currencyCode,
          flagCode: currencyToFlagCode[currencyCode],
        })
      )

      // move GBP, USD, EUR, CNY and HKD to top
      ;['GBP', 'USD', 'EUR', 'CNY', 'HKD'].forEach((currencyCode, index) => {
        const flagIndex = newFlagsCodes.findIndex(
          (el) => el.currencyCode === currencyCode
        )

        if (flagIndex >= 0) {
          const currency = newFlagsCodes[flagIndex]
          newFlagsCodes.splice(flagIndex, 1)
          newFlagsCodes.splice(index, 0, currency)
        }
      })

      setFlagCodes(newFlagsCodes)
    }
  }, [currencyRates])

  return flagCodes
}
