declare global {
  interface Window {
    dataLayer: GTMEvent[]
  }
}

export type GTMEvent = Record<string, unknown>

const initialiseDataLayer = () => {
  if (typeof window === 'undefined') {
    return
  }

  if (!('dataLayer' in window) || window.dataLayer === undefined) {
    window.dataLayer = []
  }
}

const dataLayer = {
  push: (event: GTMEvent | (() => GTMEvent)) => {
    initialiseDataLayer()

    // requestAnimationFrame being used to ensure document.title is correct before pushing
    requestAnimationFrame(() =>
      window.dataLayer.push(typeof event === 'function' ? event() : event)
    )
  },
  getHistory: () => {
    initialiseDataLayer()

    return window.dataLayer
  },
}

export const useDataLayer = () => dataLayer
