/* istanbul ignore file */
import { ExtraErrorData } from '@sentry/integrations'

export function generateServerConfig(dsn: string) {
  return {
    dsn,
    release:
      process.env['SENTRY_RELEASE'] ||
      process.env['NEXT_PUBLIC_SENTRY_RELEASE'],
    normalizeDepth: 5,
    tracesSampleRate: 0,
    environment:
      process.env['DEPLOY_ENV'] || process.env['NEXT_PUBLIC_DEPLOY_ENV'],
    enabled: process.env['NEXT_PUBLIC_DISABLE_SENTRY_PLUGIN'] !== 'true',
    integrations: [new ExtraErrorData()],
  }
}

function getEnv(preprodHost: string) {
  if (typeof window !== 'undefined') {
    const { host } = window.location
    if (host === preprodHost) {
      return 'preprod'
    }
    if (host.includes(':')) {
      return 'local'
    }
    if (host.endsWith('.vercel.app')) {
      return 'vercel'
    }
    return 'production'
  }
  return 'server'
}

interface GenerateClientConfigProps extends Record<string, unknown> {
  dsn: string
  preprodHost: string
}

export function generateClientConfig({
  dsn,
  preprodHost,
  ...extra
}: GenerateClientConfigProps) {
  return {
    dsn,
    tracesSampleRate: 0,
    allowUrls: [/https?:\/\/([a-z]+\.)?bonhams\.com/],
    release:
      process.env['SENTRY_RELEASE'] ||
      process.env['NEXT_PUBLIC_SENTRY_RELEASE'],
    environment: getEnv(preprodHost),
    normalizeDepth: 5,
    enabled: process.env['NEXT_PUBLIC_DISABLE_SENTRY_PLUGIN'] !== 'true',
    ignoreErrors: [
      // Adding as suggested by Sentry https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // CUSTOM
      'NS_ERROR_STORAGE_CONSTRAINT',
      'NS_ERROR_FILE_CORRUPTED',
      'SecurityError: The operation is insecure',
      'Blocked a frame with origin',
      'ResizeObserver loop limit exceeded',
      'https://reactjs.org/docs/error-decoder.html?invariant=418', // hydration failure.
      'https://reactjs.org/docs/error-decoder.html?invariant=423', // There was an error while hydrating.
      'https://reactjs.org/docs/error-decoder.html?invariant=425', // Text content does not match server-rendered HTML...
    ],
    ignoreUrls: [
      // Adding as suggested by Sentry https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Google Topics
      /pagead2\.googlesyndication\.com\/pagead\/buyside_topics\/set/i,
    ],
    integrations: [new ExtraErrorData({ depth: 5 })],
    ...extra,
  }
}
