import getConfig from 'next/config'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

const baseUrl = publicRuntimeConfig.WEB_BASE_URL
const skinnerBaseUrl = publicRuntimeConfig.SKINNER_BASE_URL

const { hostname } = new URL(skinnerBaseUrl)

export const skinnerSubDomainRedirects: RedirectConfig[] = [
  {
    source:
      /(^(?!(^\/$|\/about-us\/|\/sell\/|\/404\/|\/logout\/|\/robots.txt)).*$)/,
    hostname,
    destination: `${baseUrl}{{0}}`,
    permanent: true,
  },
]
