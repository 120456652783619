import styled from 'styled-components'

import { CloseIcon } from '@nx/fire/assets'
import { FireButton } from '@nx/fire/button'
import { FireImage } from '@nx/fire/image'
import { Body, Heading } from '@nx/fire/typography'

export const StyledBannerContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -5px 25px 0 rgb(0 0 0 / 10%);
  z-index: ${({ theme }) => theme.zIndex.newsletterBanner};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colours.primary.white};
`

export const StyledBannerContainerBody = styled.div`
  width: 100%;
  padding: 13px 15px;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 1190px;
  margin: 0 auto;
  z-index: 1;
`

export const StyledBannerImgContainer = styled.div`
  margin-right: 10px;

  ${({ theme }) => theme.media.up.lg} {
    margin-right: 20px;
  }
`

export const StyledBannerImage = styled(FireImage)`
  height: 56px;
  width: 64px;
  border-radius: 4px;
  object-fit: cover;

  ${({ theme }) => theme.media.up.md} {
    height: 70px;
    width: 80px;
  }

  ${({ theme }) => theme.media.up.lg} {
    height: 70px;
    width: 126px;
  }

  &[src^='data:'] {
    object-fit: contain;
  }
`

export const StyledSignupBannerTrigger = styled.div<{ $height: number }>`
  position: absolute;
  top: calc(100vh + 20%);
  height: ${({ $height }) => $height}px;
  width: 0;
`

export const StyledContainerTextBanner = styled.div`
  margin-right: auto;

  ${({ theme }) => theme.media.up.lg} {
    max-width: 41%;
    flex-basis: 41%;
  }
`

export const StyledBannerTitle = styled(Heading)`
  margin: 0 0 2px;

  ${({ theme }) => theme.media.up.md} {
    margin: 0 0 5px;
  }
`

export const StyledBannerBodyText = styled(Body)`
  display: none;
  margin: 5px 0 0;

  ${({ theme }) => theme.media.up.lg} {
    display: block;
  }
`

export const StyledMobileButton = styled(FireButton)`
  display: block;

  ${({ theme }) => theme.media.up.md} {
    display: none;
  }
`

export const StyledBannerButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledSignupButton = styled(FireButton)`
  display: none;

  ${({ theme }) => theme.media.up.md} {
    display: block;
    white-space: nowrap;
    padding: 5px 30px;
    margin-right: 45px;
  }

  ${({ theme }) => theme.media.up.lg} {
    margin-right: 58px;
  }
`

export const StyledBannerCloseButton = styled(FireButton)`
  ${({ theme }) => theme.media.up.md} {
    margin-right: 10px;
  }
`

export const StyledBannerCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.colours.primary.black};
`
