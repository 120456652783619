/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
} from 'axios'

const SAFE_HTTP_METHODS = ['GET', 'HEAD', 'OPTIONS', 'TRACE']
const DEFAULT_RETRIES = 3
let retryCount = 0
let retryMax = 0
let retryDelay = exponentDelay

interface AxiosRetryConfig {
  retry?: number | false
  retryDelay?: number
}

export type AxiosRequestConfigWithRetry<D = any> = AxiosRequestConfig<D> &
  AxiosRetryConfig & { baseURL?: string }

type CommonAxiosMethod = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  config: AxiosRequestConfigWithRetry<D>
) => Promise<R>
type CommonAxiosDataMethod = <T = any, R = AxiosResponse<T>, D = any>(
  url: string,
  data: D,
  config: AxiosRequestConfigWithRetry<D>
) => Promise<R>

interface WrappedAxiosMethods {
  get: CommonAxiosMethod
  delete: CommonAxiosMethod
  head: CommonAxiosMethod
  options: CommonAxiosMethod
  post: CommonAxiosDataMethod
  put: CommonAxiosDataMethod
  patch: CommonAxiosDataMethod
  request<T = any, R = AxiosResponse<T>, D = any>(
    config?: AxiosRequestConfigWithRetry<D>
  ): Promise<R>
}

export type WrappedAxiosInstance = Omit<
  AxiosInstance,
  'get' | 'delete' | 'head' | 'options' | 'post' | 'put' | 'patch' | 'request'
> &
  WrappedAxiosMethods &
  AxiosStatic

const denyList = new Set([
  'ENOTFOUND',
  'ENETUNREACH',

  // SSL errors from https://github.com/nodejs/node/blob/main/src/crypto/crypto_common.cc#L219-L246
  'UNABLE_TO_GET_ISSUER_CERT',
  'UNABLE_TO_GET_CRL',
  'UNABLE_TO_DECRYPT_CERT_SIGNATURE',
  'UNABLE_TO_DECRYPT_CRL_SIGNATURE',
  'UNABLE_TO_DECODE_ISSUER_PUBLIC_KEY',
  'CERT_SIGNATURE_FAILURE',
  'CRL_SIGNATURE_FAILURE',
  'CERT_NOT_YET_VALID',
  'CERT_HAS_EXPIRED',
  'CRL_NOT_YET_VALID',
  'CRL_HAS_EXPIRED',
  'ERROR_IN_CERT_NOT_BEFORE_FIELD',
  'ERROR_IN_CERT_NOT_AFTER_FIELD',
  'ERROR_IN_CRL_LAST_UPDATE_FIELD',
  'ERROR_IN_CRL_NEXT_UPDATE_FIELD',
  'OUT_OF_MEM',
  'DEPTH_ZERO_SELF_SIGNED_CERT',
  'SELF_SIGNED_CERT_IN_CHAIN',
  'UNABLE_TO_GET_ISSUER_CERT_LOCALLY',
  'UNABLE_TO_VERIFY_LEAF_SIGNATURE',
  'CERT_CHAIN_TOO_LONG',
  'CERT_REVOKED',
  'INVALID_CA',
  'PATH_LENGTH_EXCEEDED',
  'INVALID_PURPOSE',
  'CERT_UNTRUSTED',
  'CERT_REJECTED',
  'HOSTNAME_MISMATCH',
])

function exponentDelay(retryCount: number) {
  return Math.pow(2, retryCount + 1) * 200
}

export default function isRetryAllowed(error: AxiosError) {
  return !denyList.has(error.code || '')
}

export function isNetworkError(error: AxiosError) {
  return error.code === 'ECONNABORTED'
}

export function isRetryableError(error: AxiosError) {
  return (
    (isNetworkError(error) || isRetryAllowed(error)) &&
    (error.response?.status ?? 0) >= 500
  )
}

export function isSafeRequestError(error: AxiosError) {
  return (
    !!error.config &&
    isRetryableError(error) &&
    SAFE_HTTP_METHODS.includes((error.config.method ?? '').toUpperCase())
  )
}

export function isTheLastRetry(error: AxiosError) {
  if (!error.config || !isSafeRequestError(error)) {
    return true
  }

  return retryMax === 0 || (retryMax && retryCount >= retryMax)
}

export function axiosRetryInterceptor(
  axiosInstance: AxiosInstance
): WrappedAxiosInstance {
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      /* istanbul ignore next */
      if (!error.config) {
        return Promise.reject(error)
      }

      if (retryMax && retryCount < retryMax && isSafeRequestError(error)) {
        retryCount += 1
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            /* istanbul ignore next */
            if (!error.config) {
              return reject(error)
            }
            resolve(axiosInstance.request(error.config))
          }, retryDelay(retryCount))
        })
      }

      return Promise.reject(error)
    }
  )

  return wrappedInstance(axiosInstance)
}

function wrappedInstance(axiosInstance: AxiosInstance): WrappedAxiosInstance {
  function parseRetryConfig(config?: AxiosRequestConfigWithRetry) {
    retryMax = DEFAULT_RETRIES
    retryDelay = exponentDelay
    retryCount = 0

    if (config?.retry === false) {
      retryMax = 0
    } else if (typeof config?.retry === 'number') {
      retryMax = config.retry
    }
    const delay = config?.retryDelay
    if (typeof delay === 'number') {
      retryDelay = () => delay
    }
  }

  function axiosRequest(type: 'get' | 'delete' | 'head' | 'options') {
    return function <T = any, R = AxiosResponse<T>, D = any>(
      url: string,
      config: AxiosRequestConfigWithRetry<D>
    ) {
      parseRetryConfig(config)
      return axiosInstance[type]<T, R, D>(url, config)
    }
  }

  function axiosDataRequest(type: 'post' | 'put' | 'patch') {
    return function <T = any, R = AxiosResponse<T>, D = any>(
      url: string,
      data: D,
      config: AxiosRequestConfigWithRetry<D>
    ) {
      parseRetryConfig(config)
      return axiosInstance[type]<T, R, D>(url, data, config)
    }
  }

  const wrappedAxiosFunctions: WrappedAxiosMethods = {
    get: axiosRequest('get'),
    delete: axiosRequest('delete'),
    head: axiosRequest('head'),
    options: axiosRequest('options'),
    post: axiosDataRequest('post'),
    put: axiosDataRequest('put'),
    patch: axiosDataRequest('patch'),
    request<T = any, R = AxiosResponse<T>, D = any>(
      config: AxiosRequestConfigWithRetry<D>
    ) {
      parseRetryConfig(config)
      return axiosInstance.request<T, R, D>({ ...config })
    },
  }

  return { ...axiosInstance, ...wrappedAxiosFunctions } as WrappedAxiosInstance
}
