import styled from 'styled-components'

import { RotateIcon } from '@nx/fire/assets'

export const LoadingSpinner = styled(RotateIcon)`
  animation-name: ${({ theme }) => theme.animation.rotate360};
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`
