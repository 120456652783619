import { useRouter } from 'next/router'
import { useRef } from 'react'
import styled from 'styled-components'

import { FireFormSearchInput } from '@nx/fire/form-input'
import { getTranslate } from '@nx/translations'

import translate from './search-form.translate.json'

const { text } = getTranslate(translate)

export function SearchForm({ link }: SearchFormProps) {
  const router = useRouter()
  const inputRef = useRef<HTMLInputElement>(null)

  function submitSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const data = new FormData(e.currentTarget)

    if (inputRef.current !== null) {
      inputRef.current.value = ''
      inputRef.current.blur()
    }

    router.push({
      pathname: link,
      query: {
        query: data.get('query') as string,
      },
    })
  }

  return (
    <StyledFireFormSearchInput
      ref={inputRef}
      label={text('searchLabel')}
      submitButton={{ text: text('search') }}
      input={{ name: 'query', placeholder: text('searchLabel') }}
      onSubmit={submitSearch}
      action={link}
    />
  )
}

export interface SearchFormProps {
  link: string
}

const StyledFireFormSearchInput = styled(FireFormSearchInput)`
  display: none;
  margin-left: auto;
  max-width: 370px;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.media.up.xl} {
    display: flex;
  }
`
