import Cookies from 'js-cookie'
import { NextPageContext } from 'next'
import getConfig from 'next/config'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const { publicRuntimeConfig = {} } = getConfig() ?? {}

export type FeatureFlagsType = NextApiRequestCookies

type FeatureFlagsContextValues = [
  FeatureFlagsType,
  Dispatch<SetStateAction<FeatureFlagsType>>,
]

interface IfFeatureEnabledProps {
  children: ReactNode | string
  feature: string
}

interface FeatureStringProps {
  feature: string
  default?: string
}

//Context
export const FeatureFlagsContext = createContext<FeatureFlagsContextValues>([
  {},
  /* istanbul ignore next */
  (feat) => ({ ...feat }),
])

export interface ContextFeatureFlagsProps {
  children: ReactNode
  cookies?: FeatureFlagsType
}

//Provider
export const ContextFeatureFlags = memo(function ContextFeatureFlags({
  children,
  cookies = {},
}: ContextFeatureFlagsProps) {
  const [features, setFeatures] = useState<FeatureFlagsType>(() => cookies)

  useEffect(() => {
    setFeatures(() => cookies)
  }, [cookies])

  const values = useMemo<FeatureFlagsContextValues>(
    () => [features, setFeatures],
    [features]
  )

  return (
    <FeatureFlagsContext.Provider value={values}>
      {children}
    </FeatureFlagsContext.Provider>
  )
})

function resolveFeatureFlag<T = unknown>(
  /* istanbul ignore next */
  features: FeatureFlagsType = {},
  name: string,
  defaultValue: T | undefined = undefined
): T | undefined {
  let value = ''

  if (Object.prototype.hasOwnProperty.call(features, name)) {
    value = features[name] || ''
  } else if (
    publicRuntimeConfig &&
    Object.prototype.hasOwnProperty.call(publicRuntimeConfig, name)
  ) {
    value = publicRuntimeConfig[name]
  } else {
    return defaultValue
  }

  try {
    return JSON.parse(value) as T
  } catch {
    return (value as unknown as T) || defaultValue
  }
}

function changeForPreprod<T>(featureValue: T) {
  const isBoolean = ['boolean', 'undefined'].includes(typeof featureValue)
  const isPreprod = publicRuntimeConfig.DEPLOY_ENV === 'preprod'
  return isBoolean && isPreprod ? (!featureValue as T) : featureValue
}

export function useFeature<T = unknown>(
  name: string,
  defaultValue: T | undefined = undefined
): T | undefined {
  const [features] = useContext(FeatureFlagsContext)

  const featureValue = resolveFeatureFlag<T>(features, name, defaultValue)
  return changeForPreprod(featureValue)
}

export function ssrUseFeature<T = unknown>(
  req: (NextPageContext['req'] & { cookies: FeatureFlagsType }) | undefined,
  name: string,
  defaultValue: T | undefined = undefined
): T | undefined {
  const features = req?.cookies || Cookies.get()

  const featureValue = resolveFeatureFlag<T>(features, name, defaultValue)
  return changeForPreprod(featureValue)
}

export const IfFeatureEnabled = memo(function IfFeatureEnabled({
  children,
  feature,
}: IfFeatureEnabledProps) {
  const isEnabled = useFeature<boolean>(feature)
  //eslint-disable-next-line react/jsx-no-useless-fragment
  return isEnabled ? <>{children}</> : null
})

export const FeatureString = memo(function FeatureString({
  feature,
  ...props
}: FeatureStringProps) {
  const value = useFeature(feature, props['default'])
  //eslint-disable-next-line react/jsx-no-useless-fragment
  return value !== undefined ? <>{value}</> : null
})
