import { rgba } from 'polished'
import styled, { createGlobalStyle } from 'styled-components'

import { Heading } from '@nx/fire/typography'

export const StyledModal = styled.div`
  background: ${({ theme }) => theme.colours.primary.white};
  z-index: ${({ theme }) => theme.zIndex.modal + 1};
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 10px;

  &:focus {
    outline: 0;
  }

  ${({ theme }) => theme.media.up.lg} {
    width: min-content;
    min-width: 600px;
    position: relative;
    padding: 0 30px 30px;
    margin: 30px 0;
    max-height: 80vh;
    height: auto;
    border-radius: 2px;
    box-shadow: ${({ theme }) => theme.shadows.small};
  }
`

export const StyledHeader = styled.header`
  position: sticky;
  z-index: ${({ theme }) => theme.zIndex.modal + 1};
  top: 0;
  background: ${({ theme }) => theme.colours.primary.white};
  padding-bottom: 10px;
  min-height: 50px;

  ${({ theme }) => theme.media.up.lg} {
    min-height: 65px;
  }
`

export const StyledHeading = styled(Heading)`
  padding: 25px 35px 0 0;
  ${({ theme }) => theme.fontStyles.sangbleu.regular}

  ${({ theme }) => theme.media.up.lg} {
    padding-top: 38px;
  }
`

export const StyledOverlay = styled.div`
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  background: ${({ theme }) => `${rgba(theme.colours.primary.black, 0.3)}`};
  z-index: ${({ theme }) => theme.zIndex.modal};
`

export const StyledCloseButtonContainer = styled.div`
  background: ${({ theme }) => theme.colours.primary.white};
  top: 0;
  right: -10px;
  position: absolute;
  padding-bottom: 27px;

  ${({ theme }) => theme.media.up.lg} {
    top: 0;
    padding-top: 13px;
    right: -17px;
  }
`

export const StyledCloseButton = styled.button`
  all: unset;
  cursor: pointer;
  width: 44px;
  height: 44px;
  display: grid;
  place-items: center;

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colours.primary.primary};
  }
`

export const StyledReactModalBody = createGlobalStyle`
  .ReactModal__Body--open { /* stylelint-disable-line selector-class-pattern */
    width: 100%; /* Fixed issue on safari */
  }
`
