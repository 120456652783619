import * as Sentry from '@sentry/nextjs'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import Cookies from 'js-cookie'

import {
  UpdatePasswordProps,
  UserDataResponse,
  updatePassword,
  useCheckUserLoginStatus,
} from '@nx/auth'
import { axiosInstance } from '@nx/fetch'
import { getTranslate } from '@nx/translations'
import {
  CreateAccountFormValues,
  ForgotPasswordFormValues,
  LoginFormValues,
} from '@nx/types'
import {
  getCrossingMindsSessionCookie,
  removeAuthCookies,
  setAuthCookies,
  setSessionCookie,
} from '@nx/user-auth-cookies'

import translate from './hooks-use-user-auth.translate.json'

const { text } = getTranslate(translate)

export function useUserAuth({
  host,
  apiUrl,
  newPasswordFlowEnabled,
}: UseUserAuthProps): UseUserAuthReturn {
  const queryClient = useQueryClient()
  const {
    data: {
      user: { iClientNo },
    },
  } = useCheckUserLoginStatus({ apiUrl })

  const resetPassword = useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      return axiosInstance
        .post<ResetPasswordResponse>(
          newPasswordFlowEnabled
            ? '/api/com/client/reset-password/'
            : '/api/com/reset-password/',
          newPasswordFlowEnabled ? { email } : { email_address: email },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            baseURL: apiUrl,
          }
        )
        .then((response) => response.data)
        .catch(({ response: { data, status } }) => {
          if (status === 400) {
            throw new Error(data.Message)
          }
          throw new Error(text('userAuth.passwordResetFailed'))
        })
    },
  })

  const completePasswordReset = useMutation({
    mutationFn: async (args: Omit<UpdatePasswordProps, 'apiUrl'>) => {
      return updatePassword({ ...args, apiUrl }).then((response) => {
        if (response.status !== 'OK') {
          throw new Error(response.message)
        }

        return response
      })
    },
  })

  const login = useMutation({
    mutationFn: async ({
      email,
      password,
    }: {
      email: string
      password: string
    }) => {
      return axiosInstance
        .post<LoginResponse>(
          '/api/com/login/',
          {
            username: email,
            password,
            agree_terms_and_conditions: true,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            baseURL: apiUrl,
          }
        )
        .then((response) => response.data)
        .catch(({ response }) => {
          if (
            response?.status === 403 &&
            response?.data.detail === 'User is locked'
          ) {
            throw new Error(text('userAuth.accountLocked'))
          }

          if (response?.status === 401) {
            throw new Error(text('userAuth.wrongEmailPassword'))
          }

          throw new Error(text('userAuth.loginFailed'))
        })
    },
    onSuccess: async (data) => {
      setAuthCookies({
        accessToken: data.token.access,
        refreshToken: data.token.refresh,
      })

      // clear the cached recommendations from Xminds
      Cookies.remove('rec_auctions')
      Cookies.remove('rec_lots')

      try {
        const sessionToken = await axiosInstance
          .post(
            '/api/session/',
            {
              id: data.iClientNo,
              xm_id: getCrossingMindsSessionCookie(),
            },
            {
              baseURL: host,
            }
          )
          .then((response) => response.data)
        setSessionCookie(sessionToken)
      } catch (e) {
        // istanbul ignore next
        Sentry.captureException(e)
      }

      await queryClient.invalidateQueries({ queryKey: ['user'] })
      queryClient.setQueryData(['user-login-status'], {
        isLoggedIn: true,
        user: data,
      })
    },
    onError: () => {
      removeAuthCookies()

      void queryClient.invalidateQueries({ queryKey: ['user'] })
      void queryClient.invalidateQueries({ queryKey: ['user-login-status'] })
    },
  })

  const createAccount = useMutation<
    LoginResponse,
    CreateAccountError,
    CreateAccountFormValues
  >({
    mutationFn: async ({
      firstName,
      lastName,
      businessName,
      email,
      password,
      marketing,
      acceptPrivacy,
    }: CreateAccountFormValues) => {
      let body: Record<string, string | boolean> = {
        email_address: email,
        given_name: firstName,
        family_name: lastName,
        opt_in_marketing: !marketing, // ticking the box opts you out
        password,
        pwdConfirm: password,
        agree_terms_and_conditions: acceptPrivacy,
      }
      if (businessName) {
        body = { ...body, business_name: businessName }
      }
      const genericError = businessName
        ? text('userAuth.createAccountGenericBusiness')
        : text('userAuth.createAccountGenericUser')

      return axiosInstance
        .post('/api/com/register/', body, {
          headers: {
            'Content-Type': 'application/json',
          },
          baseURL: apiUrl,
          timeout: 15_000,
        })
        .then((response) => response.data)
        .catch(({ response }) => {
          if (typeof response !== 'undefined') {
            const { status, data } = response
            if (status === 400) {
              if (data?.Message) {
                throw new CreateAccountError(data.Message)
              }
              const errors = Object.values<string>(data).flatMap((val) => val)

              throw new CreateAccountError(errors)
            }
          }

          throw new CreateAccountError(genericError)
        })
    },
    onSuccess: async (data) => {
      setAuthCookies({
        accessToken: data.token.access,
        refreshToken: data.token.refresh,
      })

      try {
        const sessionToken = await axiosInstance
          .post<string>(
            '/api/session/',
            {
              id: data.integration_id,
              xm_id: getCrossingMindsSessionCookie(),
            },
            {
              baseURL: host,
            }
          )
          .then((response) => response.data)
        setSessionCookie(sessionToken)
      } catch (e) {
        // istanbul ignore next
        Sentry.captureException(e)
      }

      queryClient.invalidateQueries({ queryKey: ['user'] })
      queryClient.setQueryData(['user-login-status'], {
        isLoggedIn: true,
        user: data,
      })
    },
    onError: () => {
      removeAuthCookies()

      void queryClient.invalidateQueries({ queryKey: ['user'] })
      void queryClient.invalidateQueries({ queryKey: ['user-login-status'] })
    },
  })

  const selectInterests = useMutation({
    mutationFn: async ({ interests }: { interests: string[] }) =>
      await axiosInstance.post(
        '/api/com/client-interests/',
        {
          client_id: iClientNo,
          liked_dept: interests,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          baseURL: apiUrl,
        }
      ),
  })

  return {
    resetPassword: {
      status: resetPassword.status,
      error: resetPassword.error?.message,
      submit: resetPassword.mutate,
      reset: resetPassword.reset,
    },
    completePasswordReset: {
      status: completePasswordReset.status,
      error: completePasswordReset.error?.message,
      submit: completePasswordReset.mutate,
      reset: completePasswordReset.reset,
    },
    login: {
      status: login.status,
      error: login.error?.message,
      submit: login.mutate,
      reset: login.reset,
    },
    createAccount: {
      status: createAccount.status,
      error: createAccount.error?.messages,
      submit: createAccount.mutate,
      reset: createAccount.reset,
    },
    selectInterests: {
      status: selectInterests.status,
      submit: selectInterests.mutate,
      reset: selectInterests.reset,
    },
  }
}

export interface LoginResponse extends UserDataResponse {
  token: {
    access: string
    refresh: string
  }
  integration_id: number
}

export interface ResetPasswordResponse {
  MessageStatus: string
  Message: string
}

export interface CompletePasswordResetResponse {
  MessageStatus: string
  Message: string
}

interface AuthReturnType<SubmitType, Err = string> {
  status: ReturnType<typeof useMutation>['status']
  error?: Err
  submit: SubmitType
  reset: UseMutationResult['reset']
}

export interface UseUserAuthReturn {
  resetPassword: AuthReturnType<
    (args: Pick<ForgotPasswordFormValues, 'email'>) => void
  >
  completePasswordReset: AuthReturnType<
    (args: Omit<UpdatePasswordProps, 'apiUrl'>) => void
  >
  login: AuthReturnType<(args: LoginFormValues) => void>
  createAccount: AuthReturnType<
    (args: CreateAccountFormValues) => void,
    string[]
  >
  selectInterests: AuthReturnType<(args: { interests: string[] }) => void>
}

class CreateAccountError extends Error {
  messages: string[] = []

  constructor(messages: string | string[]) {
    super('')
    this.messages = [messages].flat()
    this.name = 'CreateAccountError'
  }
}

export interface UseUserAuthProps {
  host: string
  apiUrl: string
  newPasswordFlowEnabled: boolean
}
