import getConfig from 'next/config'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

const baseUrl = publicRuntimeConfig.WEB_BASE_URL

const isProduction = publicRuntimeConfig.DEPLOY_ENV === 'production'

// Auctions duplicated because of cross listing limitations (prod only)
const auctionIds = isProduction
  ? [
      [28659, 29142],
      [28324, 29156],
      [28859, 29196],
      [29244, 30133],
    ]
  : []
const auctionIdRedirects: RedirectConfig[] = auctionIds.map(([from, to]) => {
  const source = new RegExp(`/auction/${from}/.*`)
  return {
    source,
    destination: `${baseUrl}/auction/${to}/`,
    permanent: true,
  }
})

export const auctionRedirects: RedirectConfig[] = [
  {
    source: /\/auction\/28079\/.*/,
    destination: 'https://niftygateway.com/collections/thankyoux-bonhams',
    permanent: true,
  },
  {
    source: /\/(live|online)_auctions\/([0-9]+)\/lot\/([0-9A-Za-z]+)/,
    destination: `${baseUrl}/auction/{{1}}/lot/{{2}}`,
    permanent: true,
  },
  {
    source: /\/(live|online)_auctions\/([0-9]+)\/preview_lot\/([0-9]+)/,
    destination: `${baseUrl}/auction/{{1}}/preview-lot/{{2}}`,
    permanent: true,
  },
  {
    source: /\/(live|online)_auctions\/([0-9]+)(\/)?$/,
    destination: `${baseUrl}/auction/{{1}}`,
    permanent: true,
  },
  ...auctionIdRedirects,
]
