import getConfig from 'next/config'

import { axiosInstance } from '@nx/fetch'

const { publicRuntimeConfig } = getConfig()

export async function getSessionToken(customerId: string | number) {
  try {
    const response = await axiosInstance.post<string>(
      '/api/session/',
      {
        id: customerId,
      },
      {
        baseURL: publicRuntimeConfig.WEB_BASE_URL,
      }
    )

    return response.data
  } catch {
    return null
  }
}
