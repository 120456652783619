import getConfig from 'next/config'

import { useCheckUserLoginStatus } from '@nx/auth'

const { publicRuntimeConfig } = getConfig()

export function useGetLoginStatus() {
  return useCheckUserLoginStatus({
    apiUrl: publicRuntimeConfig.CLIENT_SERVICE_URL,
  })
}
