import { DynamicOptionsLoadingProps } from 'next/dynamic'
import { useEffect, useState } from 'react'

import { LoadingSpinner } from '@nx/fire/loading-spinner'

import { StyledLoadingContainer } from './loading.styles'

export const Loading: (props: DynamicOptionsLoadingProps) => JSX.Element = ({
  retry,
  pastDelay,
  error,
  isLoading,
  timedOut,
  ...props
}) => {
  const [isSpinnerShown, setIsSpinnerShown] = useState(false)

  useEffect(() => {
    setTimeout(() => setIsSpinnerShown(true), 200)
  }, [])

  return (
    <StyledLoadingContainer {...props}>
      {isSpinnerShown && <LoadingSpinner />}
    </StyledLoadingContainer>
  )
}
