import { createGlobalStyle, css } from 'styled-components'

// GlobalStyle cannot be snapshot tested https://github.com/masakudamatsu/nextjs-template/issues/17
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  ${({ theme: { fonts, typography, colours } }) => css`
    :root {
      font-size: ${fonts.baseSize}px;
    }

    html {
      font-size: 100%;
      scroll-behavior: smooth;
    }

    body {
      text-size-adjust: 100%;
      -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
      -webkit-font-smoothing: antialiased;
      background-color: ${colours.grey['30']};
      ${typography.body.M}
    }

    /* stylelint-disable-next-line selector-id-pattern */
    #__next {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }

    noscript {
      font-size: 0;
      display: block;
    }
  `}
`
