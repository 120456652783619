import pino from 'pino'
import 'setimmediate'

const isProduction = process.env.DEPLOY_ENV === 'production'
const isVercel = process.env.DEPLOY_ENV === 'vercel'

export const logger = pino({
  level: /* istanbul ignore next */ isProduction ? 'warn' : 'debug',
  transport:
    /* istanbul ignore next */ isProduction || isVercel
      ? undefined
      : {
          target: 'pino-pretty',
          options: {
            colorize: true,
            translateTime: 'SYS:standard',
            ignore: 'pid,hostname',
          },
        },
  formatters: {
    level: (label) => ({ level: label }),
  },
  timestamp: pino.stdTimeFunctions.isoTime, // Use ISO time format for timestamp
  serializers: {
    err: pino.stdSerializers.err, // Serialize error objects
  },
})
