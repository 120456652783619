import dynamic from 'next/dynamic'

import { FireSignupWidgetProps } from './types'
import { useNewsletterSignupBanner } from './useNewsletterSignupBanner'

const NewsletterBannerDynamic = dynamic(
  () =>
    import('./NewsletterBanner').then(
      ({ NewsletterBanner }) => NewsletterBanner
    ),
  {
    ssr: false,
  }
)

export function FireNewsletterSignup(props: FireSignupWidgetProps) {
  const { isBannerShown, isSubscribed, queryTrigger } =
    useNewsletterSignupBanner(props)

  if (!queryTrigger && (isSubscribed || !isBannerShown)) return null

  return <NewsletterBannerDynamic {...props} />
}

export default FireNewsletterSignup
