import { LocaleString, EActiveLanguages } from '@nx/smartling'

const fallbackLanguage = LocaleString.english

export function getUsersLanguage(
  acceptHeader: string | undefined
): EActiveLanguages {
  if (!acceptHeader) return fallbackLanguage

  const allAcceptedLanguages = acceptHeader
    .split(',')
    .map((weightedLanguageRange) => {
      const components = weightedLanguageRange.replace(/\s+/, '').split(';')
      return {
        tag: components[0],
        quality: components[1] ? parseFloat(components[1].split('=')[1]) : 1.0,
      }
    })
    .sort((a, b) => b.quality - a.quality)

  const languageMatch = allAcceptedLanguages.filter((languageTag) =>
    [
      LocaleString.english,
      LocaleString.french,
      LocaleString.flemish,
      LocaleString.chineseSimplified,
      LocaleString.chineseTraditional,
    ].includes(languageTag.tag.toLowerCase() as LocaleString)
  )

  return languageMatch.length > 0
    ? (languageMatch[0].tag.toLowerCase() as EActiveLanguages)
    : fallbackLanguage
}
