import { Body } from '@nx/fire/typography'
import styled from 'styled-components'

export const StyledSkinnerResetPasswordButton = styled(Body)`
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colours.primary.primary};
  text-decoration: underline;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colours.secondary.primary};
  }
`
