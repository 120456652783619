import { AppContext } from 'next/app'
import { NextRequest } from 'next/server'

export function getIPAddress(appContext: AppContext): string | undefined {
  const ip = appContext.ctx.req?.headers?.['x-forwarded-for']
    ? (appContext.ctx.req.headers['x-forwarded-for'] as string)
        .replace(' ', '')
        .split(',')[0]
    : appContext.ctx.req?.connection?.remoteAddress
        ?.replace(' ', '')
        .split(',')[0]

  const request = appContext.ctx.req as unknown as NextRequest

  if (!ip && request && request.ip) {
    return request.ip.replace(' ', '').split(',')[0]
  } else {
    return ip
  }
}
