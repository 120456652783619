import { FireButton } from '@nx/fire/button'
import { FireFormInput } from '@nx/fire/form-input'
import { useNewsletterForm } from '@nx/fire/newsletter-signup'
import { Body } from '@nx/fire/typography'
import VisuallyHidden from '@nx/fire/visually-hidden'
import { getTranslate } from '@nx/translations'

import {
  DisclaimerText,
  Form,
  FormInputWrapper,
  NewsletterFormErrorText,
  StyledHeading,
} from './fire-footer.styles'
import translate from './fire-footer.translate.json'

const { text } = getTranslate(translate)

export function NewsletterForm({
  title,
  supportingText,
  placeholder,
  submit,
  successTitle,
  successBody,
  apiBaseUrl,
}: NewsletterFormProps) {
  const { handleSubmit, inputProps, formState, error } = useNewsletterForm({
    apiUrl: apiBaseUrl,
  })

  return (
    <Form onSubmit={handleSubmit} noValidate>
      {formState !== 'SUCCESS' ? (
        <>
          <StyledHeading as="h2" $level={6}>
            {title || text('formTitle')}
          </StyledHeading>
          {formState === 'ERROR' && !error && (
            <NewsletterFormErrorText $level="S">
              {text('formError')}
            </NewsletterFormErrorText>
          )}
          <FormInputWrapper>
            <div>
              <label>
                <VisuallyHidden>{text('formPlaceholder')}</VisuallyHidden>
                <FireFormInput
                  type="email"
                  isLarge
                  placeholder={placeholder || text('formPlaceholder')}
                  errorTexts={error ? [error] : []}
                  hasError={Boolean(error)}
                  {...inputProps}
                />
              </label>
            </div>

            <FireButton type="submit" disabled={formState === 'SUBMITTING'}>
              {submit || text('formSubmit')}
            </FireButton>
          </FormInputWrapper>

          <DisclaimerText $level="XS">{text('formDisclaimer')}</DisclaimerText>

          {supportingText && <Body $level="XS">{supportingText}</Body>}
        </>
      ) : (
        <>
          <StyledHeading as="h3" $level={6}>
            {successTitle || text('formThankYouTitle')}
          </StyledHeading>
          <Body>{successBody || text('formThankYouBody')}</Body>
        </>
      )}
    </Form>
  )
}

export interface NewsletterFormProps {
  title?: string
  supportingText?: string
  placeholder?: string
  submit?: string
  successTitle?: string
  successBody?: string
  apiBaseUrl: string
}
