import { createGlobalStyle, css } from 'styled-components'

// GlobalStyle cannot be snapshot tested https://github.com/masakudamatsu/nextjs-template/issues/17
/* istanbul ignore next */
export const GlobalTypography = createGlobalStyle`
  ${({ theme: { fontStyles, fonts, typography, colours, helpers } }) =>
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${fonts.headerFont};
        color: ${colours.primary.black};
      }

      p {
        color: ${colours.primary.black};
        font-family: ${fonts.bodyFont};
        font-size: ${fonts.baseSize}px;
        line-height: 1.5rem;
        margin-bottom: 6px;
      }

      h1 {
        ${typography.heading.heading1};
      }

      h2 {
        ${typography.heading.heading2};
      }

      h3 {
        ${typography.heading.heading3};
        margin: 10px 0;
      }

      h4 {
        ${typography.heading.heading4};
      }

      h5 {
        ${typography.heading.heading5};
      }

      h6 {
        ${typography.heading.heading6};
      }

      .body-header {
        ${typography.label.M}
        padding-top: 20px;
        ${helpers.topSeparationLine}
      }

      .uppercase {
        text-transform: uppercase;
      }

      .bold {
        font-weight: bold;
      }

      a {
        color: ${colours.primary.primary};
        text-decoration: none;

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      button {
        font-family: ${fonts.bodyFont};
      }

      i,
      em {
        font-style: italic;
      }

      b,
      strong {
        ${fontStyles.sourceSans.bold}
      }
    `}
`
