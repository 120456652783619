import { axiosInstance } from '@nx/fetch'

export async function marketingSignup(baseURL: string, email: string) {
  return axiosInstance.post(
    '/api/marketing/signup/',
    {
      email,
      customerSource: 'WWWMKT',
    },
    {
      baseURL,
    }
  )
}
