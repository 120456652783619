import { ParsedUrlQuery } from 'querystring'

export function urlWithPersistentQueryParams(
  url: string,
  query: ParsedUrlQuery = {}
) {
  const queryParams = new URLSearchParams(query as Record<string, string>)
  const newParams: Record<string, string> = {}

  ;['utm_source', 'utm_medium', 'utm_campaign', 'cid', 'sale', 'ecid'].forEach(
    (param) => {
      const value = queryParams.get(param)
      if (value) {
        newParams[param] = value
      }
    }
  )

  const finalQueryParams = new URLSearchParams(newParams)
  const hasQueryParams = Boolean(finalQueryParams.toString())
  let hasHost = false
  let parsedUrl = {} as URL

  try {
    parsedUrl = new URL(url)
    hasHost = true
  } catch {
    // do nothing
  }

  if (hasHost) {
    let path = `${parsedUrl.origin}${parsedUrl.pathname}`

    path = hasQueryParams ? `${path}?${finalQueryParams.toString()}` : path
    return cleanDoubleSlashes(path)
  }

  try {
    parsedUrl = new URL(url, 'https://www.example.com')
  } catch {
    // do nothing
  }

  return cleanDoubleSlashes(
    hasQueryParams
      ? `${parsedUrl.pathname}?${finalQueryParams.toString()}`
      : parsedUrl.pathname
  )
}

export function cleanDoubleSlashes(url: string) {
  return url.replace(/([^:])(\/\/+)/g, '$1/')
}

export function populateUrl(
  baseUrl: string,
  path: string,
  ...params: (Record<string, string | number | boolean> | string)[]
) {
  const url = combineUrlAndParams(new URL(path, baseUrl), params)

  return cleanDoubleSlashes(url.toString())
}

export function populateRelativeUrl(
  path: string,
  ...params: (Record<string, string | number | boolean> | string)[]
) {
  // this is only used to generate a URL object, so the baseURL is not needed at all. Setting https://bonhams.com as a placeholder
  const url = combineUrlAndParams(new URL(path, 'https://bonhams.com'), params)

  return cleanDoubleSlashes(`${url.pathname}${url.search}`)
}

export function combineUrlAndParams(
  url: URL,
  params: (Record<string, string | number | boolean> | string)[]
) {
  if (params.length) {
    params.forEach((p) => {
      const parsedParams =
        typeof p === 'string'
          ? p
              .split('&')
              .map((param) => {
                const splittedParam = param.split('=')
                return splittedParam.length === 2 ? splittedParam : []
              })
              .filter((a) => a.length)
          : Object.entries(p)
      parsedParams.forEach(([key, value]) => {
        if (url.searchParams.has(key)) {
          url.searchParams.set(key, value.toString())
        } else {
          url.searchParams.append(key, value.toString())
        }
      })
    })
  }

  return url
}
