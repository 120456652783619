import styled, { css } from 'styled-components'
import { Body, BodyProps } from '@nx/fire/typography'
import { FireButton, FireButtonProps } from '@nx/fire/button'

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colours.primary.white};
    box-shadow: ${theme.shadows.small};
    display: flex;
    justify-content: center;
    padding: 10px 15px;

    ${theme.media.up.lg} {
      padding: 15px;
    }
  `}
`

export const Inner = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 640px;
    width: 100%;

    ${theme.media.up.lg} {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  `}
`

export const StyledBody = styled(Body)<BodyProps>`
  ${({ theme }) => css`
    margin-bottom: 10px;

    ${theme.media.up.lg} {
      margin: 0;
    }
  `}
`

export const StyledButton = styled(FireButton)<FireButtonProps>`
  ${({ theme }) => css`
    align-self: flex-start;

    ${theme.media.up.lg} {
      align-self: auto;
    }
  `}
`
