import styled, { css } from 'styled-components'

import { StyledSection } from '@nx/fire/section'

export const ContentContainer = styled.main`
  flex-grow: 1;

  ${({ theme: { spacing, media } }) => css`
    ${StyledSection}:first-of-type {
      margin-top: 30px;

      ${media.up.xl} {
        margin-top: ${spacing.verticalSpacer.small}px;
      }
    }

    ${StyledSection}:last-of-type {
      margin-bottom: 90px;
    }

    &:empty {
      min-height: 100vh;
    }
  `}
`
