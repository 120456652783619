'use client'

import styled, { css } from 'styled-components'

import { SectionProps } from './fire-section'

export const StyledSection = styled.section<{
  $bleed: SectionProps['bleed']
  $background: SectionProps['background']
  $verticalSpacing: SectionProps['verticalSpacing']
}>`
  ${({ theme, $bleed, $background, $verticalSpacing = 'full' }) => css`
    ${$background === 'highlight'
      ? `background-color: ${theme.colours.tertiary.primary};`
      : $background === 'white'
        ? `background-color: ${theme.colours.primary.white};`
        : $background === 'off-white'
          ? `background-color: ${theme.colours.grey['30']};`
          : null}

    display: grid;
    justify-content: center;
    grid-template-columns: minmax(
      0,
      ${$bleed === 'partial' ? '1440px' : $bleed === 'full' ? '1fr' : '1170px'}
    );
    margin: ${theme.spacing.verticalSpacer.small}px auto 0;

    ${!$bleed && 'padding: 0 15px;'}
    ${theme.media.up.lg} {
      margin-top: ${theme.spacing.verticalSpacer.large}px;
      ${!$bleed && 'padding: 0 20px;'}
    }

    ${theme.media.up.xxl} {
      ${!$bleed && 'padding: 0 25px;'}
    }

    ${$verticalSpacing === 'none' &&
    css`
      margin: 0 auto !important;
    `}

    ${$verticalSpacing === 'top-only' &&
    css`
      margin-bottom: 0 !important;
    `}

    ${$verticalSpacing === 'bottom-only' &&
    css`
      margin-top: 0 !important;
      margin-bottom: ${theme.spacing.verticalSpacer.small}px;

      ${theme.media.up.lg} {
        margin-bottom: ${theme.spacing.verticalSpacer.large}px;
      }
    `}
  `}
`
