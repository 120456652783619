import getConfig from 'next/config'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

const baseUrl = publicRuntimeConfig.WEB_BASE_URL
const skinnerBaseUrl = publicRuntimeConfig.SKINNER_BASE_URL

const globalSettings: Pick<RedirectConfig, 'hostname' | 'permanent'> = {
  hostname: 'www.skinnerinc.com',
  permanent: true,
} as const

const redirects: Pick<RedirectConfig, 'source' | 'destination'>[] = [
  // Homepage
  {
    source: /^\/$/,
    destination: skinnerBaseUrl,
  },
  // Auctions
  {
    source: '/auctions/',
    destination: `${baseUrl}/auctions/upcoming`,
  },
  // Buying
  {
    source: '/buying/faq/',
    destination: `${baseUrl}/stories/34776/`,
  },
  {
    source: '/buying/',
    destination: `${baseUrl}/stories/34776/`,
  },
  // Calendar
  {
    source: '/calendar/',
    destination: `${baseUrl}/auctions/upcoming`,
  },
  // Careers
  {
    source: '/about/careers/current-job-openings/',
    destination: `${baseUrl}/stories/31936/`,
  },
  // Contact
  {
    source: '/connect/contact-us/',
    destination: `${baseUrl}/video/30262/`,
  },
  // Departments
  {
    source: '/about/departments/20th-century-design/',
    destination: `${baseUrl}/department/MDS/`,
  },
  {
    source: '/about/departments/american-european-works-of-art/',
    destination: `${baseUrl}/department/PIC-AMP/`,
  },
  {
    source: '/about/departments/american-furniture-decorative-art/',
    destination: `${baseUrl}/department/FUR/`,
  },
  {
    source: '/about/departments/american-indian-tribal-art/',
    destination: `${baseUrl}/department/ETH/`,
  },
  {
    source: '/about/departments/antique-vehicles/',
    destination: `${publicRuntimeConfig.CARS_BASE_URL}/`,
  },
  {
    source: '/about/departments/asian-works-of-art/',
    destination: `${baseUrl}/department/ORI-CHI/`,
  },
  {
    source: '/about/departments/books-manuscripts/',
    destination: `${baseUrl}/department/BOK/`,
  },
  {
    source: '/about/departments/ceramics/',
    destination: `${baseUrl}/department/CER-BRI/`,
  },
  {
    source: '/about/departments/science-technology-clocks/',
    destination: `${baseUrl}/department/CLK/`,
  },
  {
    source: '/about/departments/coins-currency/',
    destination: `${baseUrl}/department/CNS/`,
  },
  {
    source: '/about/departments/couture-textiles/',
    destination: `${baseUrl}/department/HBS/`,
  },
  {
    source: '/about/departments/european-furniture-decorative-art/',
    destination: `${baseUrl}/department/FUR/`,
  },
  {
    source: '/about/departments/fine-jewelry/',
    destination: `${baseUrl}/department/JWL/`,
  },
  {
    source: '/about/departments/judaica/',
    destination: `${baseUrl}/department/SIL/`,
  },
  {
    source: '/about/departments/musical-instruments/',
    destination: `${baseUrl}/departments/`,
  },
  {
    source: '/about/departments/oriental-rugs-carpets/',
    destination: `${baseUrl}/department/RUG/`,
  },
  {
    source: '/about/departments/toys-dolls-collectibles/',
    destination: `${baseUrl}/departments/`,
  },
  {
    source: '/about/departments/fine-wines/',
    destination: `${baseUrl}/department/WIN/`,
  },
  {
    source: '/about/departments/discovery/',
    destination: `${baseUrl}/departments/`,
  },
  {
    source: /\/about\/departments\/.*/,
    destination: `${baseUrl}/departments/`,
  },
  // People
  {
    source: '/about/specialists/stephen-l-fletcher/',
    destination: `${baseUrl}/specialists/5829/`,
  },
  {
    source: '/about/specialists/karen-m-keane/',
    destination: `${baseUrl}/specialists/5800/`,
  },
  {
    source: '/about/specialists/lagina-austin/',
    destination: `${baseUrl}/people/`,
  },
  {
    source: '/about/specialists/carly-babione/',
    destination: `${baseUrl}/specialists/5806/`,
  },
  {
    source: '/about/specialists/richard-belkin/',
    destination: `${baseUrl}/specialists/5804/`,
  },
  {
    source: '/about/specialists/john-colasacco/',
    destination: `${baseUrl}/specialists/5835/`,
  },
  {
    source: '/about/specialists/john-dorfman/',
    destination: `${baseUrl}/specialists/5817/`,
  },
  {
    source: '/about/specialists/jonathan-dowling/',
    destination: `${baseUrl}/specialists/5812/`,
  },
  {
    source: '/about/specialists/helen-eagles/',
    destination: `${baseUrl}/specialists/5826/`,
  },
  {
    source: '/about/specialists/michael-r-evans/',
    destination: `${baseUrl}/specialists/5813/`,
  },
  {
    source: '/about/specialists/christopher-d-fox/',
    destination: `${baseUrl}/specialists/5790/`,
  },
  {
    source: '/about/specialists/elizabeth-haff/',
    destination: `${baseUrl}/specialists/5830/`,
  },
  {
    source: '/about/specialists/paul-ohara/',
    destination: `${baseUrl}/specialists/5841/`,
  },
  {
    source: '/about/specialists/joseph-hyman/',
    destination: `${baseUrl}/specialists/3914/`,
  },
  {
    source: '/about/specialists/marie-keep/',
    destination: `${baseUrl}/specialists/5823/`,
  },
  {
    source: '/about/specialists/suhyung-kim/',
    destination: `${baseUrl}/specialists/5803/`,
  },
  {
    source: '/about/specialists/leah-kingman/',
    destination: `${baseUrl}/specialists/5788/`,
  },
  {
    source: '/about/specialists/colin-kraft/',
    destination: `${baseUrl}/specialists/5824/`,
  },
  {
    source: '/about/specialists/louis-krieger/',
    destination: `${baseUrl}/specialists/5797/`,
  },
  {
    source: '/about/specialists/james-leighton/',
    destination: `${baseUrl}/specialists/5688/`,
  },
  {
    source: '/about/specialists/kathleen-m-leland/',
    destination: `${baseUrl}/specialists/5827/`,
  },
  {
    source: '/about/specialists/gloria-lieberman/',
    destination: `${baseUrl}/specialists/5852/`,
  },
  {
    source: '/about/specialists/benjamin-mini/',
    destination: `${baseUrl}/specialists/5834/`,
  },
  {
    source: '/about/specialists/stephanie-opolski/',
    destination: `${baseUrl}/specialists/5838/`,
  },
  {
    source: '/about/specialists/kaitlin-shinnick/',
    destination: `${baseUrl}/specialists/5863/`,
  },
  {
    source: '/about/specialists/kerry-shrives/',
    destination: `${baseUrl}/specialists/5689/`,
  },
  {
    source: '/about/specialists/stuart-slavid/',
    destination: `${baseUrl}/specialists/5832/`,
  },
  {
    source: '/about/specialists/robin-sr-starr/',
    destination: `${baseUrl}/specialists/5828/`,
  },
  {
    source: '/about/specialists/emily-stegner/',
    destination: `${baseUrl}/people/`,
  },
  {
    source: '/about/specialists/elizabeth-y-turner/',
    destination: `${baseUrl}/specialists/5810/`,
  },
  {
    source: '/about/specialists/katie-banser-whittle/',
    destination: `${baseUrl}/specialists/5825/`,
  },
  {
    source: /\/about\/specialists\/.*/,
    destination: `${baseUrl}/people/`,
  },
  // Events
  {
    source: /\/about\/events\/.*/,
    destination: `${baseUrl}/valuation_events/`,
  },
  // Location
  {
    source: '/about/directions-to-skinner/',
    destination: `${baseUrl}/location/`,
  },
  {
    source: '/about/visit-skinner-auction-house/boston-gallery/',
    destination: `${baseUrl}/location/BOS/`,
  },
  {
    source: '/about/visit-skinner-auction-house/marlborough-gallery/',
    destination: `${baseUrl}/location/MRL/`,
  },
  {
    source: '/about/visit-skinner-auction-house/new-york-gallery/',
    destination: `${baseUrl}/location/NYS/`,
  },
  {
    source: '/about/visit-skinner-auction-house/maine-gallery/',
    destination: `${baseUrl}/location/DMC/`,
  },
  {
    source: '/about/visit-skinner-auction-house/florida-gallery/',
    destination: `${baseUrl}/location/SE/`,
  },
  {
    source: '/about/visit-skinner-auction-house/',
    destination: `${baseUrl}/location/`,
  },
  // About
  {
    source: '/about/antiques-roadshow/',
    destination: `${baseUrl}/stories/34775/`,
  },
  {
    source: '/about/museums-institutions/',
    destination: `${baseUrl}/services/VAL-MUS/`,
  },
  // {
  //   source: '/about/',
  //   destination: `${skinnerBaseUrl}/about-us/`,
  // },
  {
    source: '/about/',
    destination: `${baseUrl}/about_us/`,
  },
  // Departments - Speciality Areas - American Antiques
  {
    source: '/specialty-areas/american-antiques/folk-art-auction/',
    destination: `${baseUrl}/department/FUR-AME/`,
  },
  {
    source: '/specialty-areas/american-antiques/marine-art-auction/',
    destination: `${baseUrl}/department/PIC-MAR/`,
  },
  {
    source: '/specialty-areas/american-antiques/',
    destination: `${baseUrl}/department/FUR-AME/`,
  },
  // Departments - Speciality Areas - Antiques Furniture Auction
  {
    source:
      '/specialty-areas/antique-furniture-auction/early-american-furniture/',
    destination: `${baseUrl}/department/FUR-AME/`,
  },
  {
    source:
      '/specialty-areas/antique-furniture-auction/antique-european-furniture/',
    destination: `${baseUrl}/department/FUR/`,
  },
  {
    source:
      '/specialty-areas/antique-furniture-auction/asian-furniture-auction/',
    destination: `${baseUrl}/department/ORI-CHI/`,
  },
  {
    source:
      '/specialty-areas/antique-furniture-auction/modern-furniture-auction/',
    destination: `${baseUrl}/department/MDS/`,
  },
  {
    source: '/specialty-areas/antique-furniture-auction/',
    destination: `${baseUrl}/department/FUR/`,
  },
  // Departments - Speciality Areas - Ceramics and Pottery
  {
    source: '/specialty-areas/ceramics-and-pottery/american-pottery-auction/',
    destination: `${baseUrl}/department/FUR-AME/`,
  },
  {
    source: '/specialty-areas/ceramics-and-pottery/antique-porcelain-auction/',
    destination: `${baseUrl}/department/CER-EUR/`,
  },
  {
    source: '/specialty-areas/ceramics-and-pottery/asian-ceramics-auction/',
    destination: `${baseUrl}/department/ORI-CHI/`,
  },
  {
    source: '/specialty-areas/ceramics-and-pottery/modern-pottery-auction/',
    destination: `${baseUrl}/department/MDS/`,
  },
  {
    source: '/specialty-areas/ceramics-and-pottery/',
    destination: `${baseUrl}/department/CER-EUR/`,
  },
  // Departments - Speciality Areas - Asian Art Auction
  {
    source: '/specialty-areas/asian-art-auction/chinese-art/',
    destination: `${baseUrl}/department/ORI-CHI/`,
  },
  {
    source: '/specialty-areas/asian-art-auction/japanese-art/',
    destination: `${baseUrl}/department/ORI-JPN/`,
  },
  {
    source: '/specialty-areas/asian-art-auction/korean-art/',
    destination: `${baseUrl}/department/ORI-JPN/`,
  },
  {
    source: '/specialty-areas/asian-art-auction/',
    destination: `${baseUrl}/department/ORI-CHI/`,
  },
  // Departments - Speciality Areas - Antique Vehicle Auction Classic Car Appraisal
  {
    source: '/specialty-areas/antique-vehicle-auction-classic-car-appraisal/',
    destination: `${publicRuntimeConfig.CARS_BASE_URL}/`,
  },
  // Departments - Speciality Areas - Antique Books
  {
    source:
      '/specialty-areas/antique-books/historical-letters-documents-auction/',
    destination: `${baseUrl}/department/BOK/`,
  },
  {
    source: '/specialty-areas/antique-books/antique-maps-auction/',
    destination: `${baseUrl}/department/BOK/`,
  },
  {
    source: '/specialty-areas/antique-books/natural-history-prints-auction/',
    destination: `${baseUrl}/department/BOK/`,
  },
  {
    source: '/specialty-areas/antique-books/',
    destination: `${baseUrl}/department/BOK/`,
  },
  // Departments - Speciality Areas - Antique Clock Auction
  {
    source: '/specialty-areas/antique-clock-auction/american-clock-auction/',
    destination: `${baseUrl}/department/CLK/`,
  },
  {
    source:
      '/specialty-areas/antique-clock-auction/antique-english-french-clocks/',
    destination: `${baseUrl}/department/CLK/`,
  },
  {
    source: '/specialty-areas/antique-clock-auction/watches/',
    destination: `${baseUrl}/departments/WCH/`,
  },
  {
    source: '/specialty-areas/antique-clock-auction/',
    destination: `${baseUrl}/department/CLK/`,
  },
  // Departments - Speciality Areas - Coins Currency
  {
    source: '/specialty-areas/coins-currency/',
    destination: `${baseUrl}/department/CNS/`,
  },
  // Departments - Speciality Areas - Antique Collectibles
  {
    source: '/specialty-areas/antique-collectibles/antique-doll-auction/',
    destination: `${baseUrl}/departments/`,
  },
  {
    source: '/specialty-areas/antique-collectibles/',
    destination: `${baseUrl}/department/COL-ENT/`,
  },
  // Departments - Speciality Areas - Vintage Lighting Antique Glass
  {
    source: '/specialty-areas/vintage-lighting-antique-glass/',
    destination: `${baseUrl}/department/CER-GLA/`,
  },
  // Departments - Speciality Areas - European Antiques
  {
    source: '/specialty-areas/european-antiques/',
    destination: `${baseUrl}/department/FUR/`,
  },
  // Departments - Speciality Areas - Find Art Auction
  {
    source: '/specialty-areas/fine-art-auction/fine-paintings-auction/',
    destination: `${baseUrl}/department/PIC-PRT`,
  },
  {
    source: '/specialty-areas/fine-art-auction/fine-prints-auction/',
    destination: `${baseUrl}/departments/PIC-PRT/`,
  },
  {
    source: '/specialty-areas/fine-art-auction/fine-art-sculpture-auction/',
    destination: `${baseUrl}/departments/WOA/`,
  },
  {
    source: '/specialty-areas/fine-art-auction/fine-art-photographs-auction/',
    destination: `${baseUrl}/department/PHO/`,
  },
  {
    source: '/specialty-areas/fine-art-auction/',
    destination: `${baseUrl}/department/PIC-AMP/`,
  },
  // Departments - Speciality Areas - Jewelry and Gemstones
  {
    source: '/specialty-areas/jewelry-and-gemstones/',
    destination: `${baseUrl}/department/JWL/`,
  },
  // Departments - Speciality Areas - Military Antiques
  {
    source: '/specialty-areas/military-antiques/',
    destination: `${baseUrl}/department/ARM/`,
  },
  // Departments - Speciality Areas - Modern Design Auction
  {
    source: '/specialty-areas/modern-design-auction/',
    destination: `${baseUrl}/department/MDS/`,
  },
  // Departments - Speciality Areas - Stringed Instrument Auction
  {
    source: '/specialty-areas/stringed-instrument-auction/',
    destination: `${baseUrl}/departments/`,
  },
  // Departments - Speciality Areas - Science Technology
  {
    source: '/specialty-areas/science-technology/',
    destination: `${baseUrl}/department/COL-SCI/`,
  },
  // Departments - Speciality Areas - Silvery Auction
  {
    source: '/specialty-areas/silver-auction/antique-judaica/',
    destination: `${baseUrl}/department/SIL/`,
  },
  {
    source: '/specialty-areas/silver-auction/',
    destination: `${baseUrl}/department/SIL/`,
  },
  // Departments - Speciality Areas - Vintage Textiles Auction
  {
    source: '/specialty-areas/vintage-textiles-auction/oriental-rugs/',
    destination: `${baseUrl}/department/RUG/`,
  },
  {
    source: '/specialty-areas/vintage-textiles-auction/',
    destination: `${baseUrl}/department/RUG/`,
  },
  // Departments - Speciality Areas - Tribal Art Auction
  {
    source: '/specialty-areas/tribal-art-auction/american-indian-art-auction/',
    destination: `${baseUrl}/department/ETH/`,
  },
  {
    source:
      '/specialty-areas/tribal-art-auction/oceanic-polynesian-art-auction/',
    destination: `${baseUrl}/department/AFR/`,
  },
  {
    source: '/specialty-areas/tribal-art-auction/pre-columbian-art-auction/',
    destination: `${baseUrl}/department/AFR/`,
  },
  {
    source: '/specialty-areas/tribal-art-auction/',
    destination: `${baseUrl}/department/AFR/`,
  },
  // Departments - Speciality Areas - Vintage Wine Acution
  {
    source: '/specialty-areas/vintage-wine-auction/',
    destination: `${baseUrl}/department/WIN/`,
  },
  // Departments - Speciality Areas - Rare Spirits Auction
  {
    source: '/specialty-areas/rare-spirits-auction/',
    destination: `${baseUrl}/department/WHI/`,
  },
  // Departments - Speciality Areas
  {
    source: '/specialty-areas/',
    destination: `${baseUrl}/departments/`,
  },
  // Legal
  {
    source: '/connect/conditions-of-sale/',
    destination: `${baseUrl}/legals/`,
  },
  {
    source: '/connect/forms/',
    destination: `${baseUrl}/stories/34776/`,
  },
  {
    source: '/connect/terms/',
    destination: `${baseUrl}/legals/`,
  },
  {
    source: '/connect/privacy-policy/',
    destination: `${baseUrl}/legals/`,
  },
  // Login, Profile & Registration
  {
    source: /^\/secure\/.*$/,
    destination: `${skinnerBaseUrl}/login/`,
  },
  // News & Stories
  {
    source: '/news/category/blog/',
    destination: `${baseUrl}/stories/`,
  },
  {
    source: '/news/news/',
    destination: `${baseUrl}/press_release/`,
  },
  {
    source: '/news/category/event/',
    destination: `${baseUrl}/valuation_events/`,
  },
  {
    source: '/news/category/video/',
    destination: `${baseUrl}/stories/`,
  },
  {
    source: '/news/category/',
    destination: `${baseUrl}/stories/`,
  },
  {
    source: '/news/author/',
    destination: `${baseUrl}/stories/`,
  },
  {
    source: /\/news\/((\?.*)?)/,
    destination: `${baseUrl}/press_release/`,
  },
  // Search
  {
    source: '/search',
    destination: `${baseUrl}/search/`,
  },
  // Selling
  {
    source: '/selling/sell-at-auction/',
    destination: `${skinnerBaseUrl}/sell/`,
  },
  {
    source: '/selling/sell-collection-at-auction/',
    destination: `${baseUrl}/services/TRE/`,
  },
  {
    source: '/selling/valuation-services/',
    destination: `${baseUrl}/services/VAL-MUS/`,
  },
  {
    source: '/selling/faq/',
    destination: `${baseUrl}/stories/34777/`,
  },
  {
    source: '/selling/mail-photos/',
    destination: 'https://www.skinnerinc.com/selling/online-appraisal/',
  },
  {
    source: '/selling/',
    destination: `${skinnerBaseUrl}/sell/`,
  },
  // Services
  {
    source: '/services/auction-services/',
    destination: `${baseUrl}/stories/34777/`,
  },
  {
    source: '/services/payment-and-shipping/',
    destination: `${baseUrl}/stories/34925/`,
  },
  {
    source: '/services/local-shipping-firms/',
    destination: `${baseUrl}/stories/34925/`,
  },
  {
    source: '/services/collection-of-goods/',
    destination: `${baseUrl}/stories/34925/`,
  },
  {
    source: '/services/fine-jewelry-shipping/',
    destination: `${baseUrl}/stories/34925/`,
  },
  {
    source: '/services/',
    destination: `${baseUrl}/services/TRE/`,
  },
]

export const skinnerIncRedirects: RedirectConfig[] = redirects.map(
  (redirect) => ({ ...globalSettings, ...redirect })
)
