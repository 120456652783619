import { AxiosInstance, InternalAxiosRequestConfig } from 'axios'

import { removeAuthCookies } from '@nx/user-auth-cookies'

import { requestTokenRefreshSuccess } from './requestTokenRefreshSuccess'

export const requestTokenRefresh = ({
  authenticatedAxiosClient,
  refreshToken,
  originalRequest,
}: {
  authenticatedAxiosClient: AxiosInstance
  refreshToken: string
  originalRequest: InternalAxiosRequestConfig<Record<string, string> | string>
}) =>
  authenticatedAxiosClient
    .post('/api/token/refresh/', {
      refresh: refreshToken,
    })
    .then(
      requestTokenRefreshSuccess({ originalRequest, authenticatedAxiosClient })
    )
    .catch((error) => {
      removeAuthCookies()

      return error
    })
