import styled, { css } from 'styled-components'

import { Body } from '@nx/fire/typography'

export const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const StyledIconContainer = styled.div<{
  $iconPosition: 'start' | 'end'
}>`
  ${({ $iconPosition }) => css`
    height: 22px;
    position: absolute;
    top: 50%;
    ${$iconPosition === 'start' ? `left: 5px;` : ''}
    ${$iconPosition === 'end' ? `right: 5px;` : ''}
    transform: translateY(-50%);
    width: 22px;

    svg {
      width: 100%;
      height: 100%;
    }
  `}
`

export const StyledInput = styled(Body)<{
  $hasError?: boolean
  $hasStartIcon?: boolean
  $hasEndIcon?: boolean
  $isLarge?: boolean
}>`
  ${({
    theme: { colours, components },
    $hasError,
    $hasStartIcon,
    $hasEndIcon,
    $isLarge,
  }) => css`
    border-radius: 2px;
    height: ${$isLarge ? `46px` : `34px`};
    padding: ${$isLarge ? `8px 0 8px 8px` : `5px 0 5px 8px`};
    margin: 2px 0 1px;
    box-sizing: border-box;
    width: 100%;

    &::placeholder {
      ${({ theme }) => theme.fontStyles.sourceSans.light};
      font-style: italic;
      color: ${colours.grey['90']};
    }

    ${$hasStartIcon ? `padding-left: 30px;` : ''}
    ${$hasEndIcon ? `padding-right: 30px;` : ''}
    border: 1px solid ${$hasError ? colours.status.error : colours.grey['45']};

    ${!$hasError
      ? css`
          &:not(:placeholder-shown) {
            border: 1px solid ${colours.grey['90']};
          }
        `
      : ''}

    ${components?.FireFormInput}
  `}
`

export const StyledErrorTextContainerDiv = styled.div<{ $hasError?: boolean }>`
  ${({ $hasError }) => {
    if ($hasError) {
      return css`
        display: initial;
      `
    } else {
      return css`
        display: none;
      `
    }
  }}
`

export const StyledErrorTextDiv = styled.div`
  ${({ theme: { colours } }) => css`
    ${({ theme }) => theme.typography.body.XS}

    color: ${colours.status.error};
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`
