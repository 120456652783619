import {
  ButtonHTMLAttributes,
  FormHTMLAttributes,
  InputHTMLAttributes,
  forwardRef,
  useId,
} from 'react'

import { SearchIcon } from '@nx/fire/assets'
import VisuallyHidden from '@nx/fire/visually-hidden'

import {
  StyledSearchInput,
  StyledSearchSubmit,
} from './fire-form-search-input.styles'

export type FireFormSearchInputProps = {
  input?: InputHTMLAttributes<HTMLInputElement>
  submitButton: ButtonHTMLAttributes<HTMLButtonElement> & { text: string }
  label: string
} & FormHTMLAttributes<HTMLFormElement>

export const FireFormSearchInput = forwardRef<
  HTMLInputElement,
  FireFormSearchInputProps
>(({ input = {}, label, submitButton, ...props }, ref) => {
  const { text: submitButtonText, ...submitButtonProps } = submitButton
  const id = useId()

  return (
    <form method="get" role="search" {...props}>
      <label htmlFor={id}>
        <VisuallyHidden>{label}</VisuallyHidden>
      </label>
      <StyledSearchInput
        ref={ref}
        type="text"
        autoComplete="off"
        id={id}
        endIcon={
          <StyledSearchSubmit
            type="submit"
            data-testid="search-button"
            {...submitButtonProps}
          >
            <SearchIcon width="25" height="25" title={submitButtonText} />
          </StyledSearchSubmit>
        }
        {...input}
      />
    </form>
  )
})
