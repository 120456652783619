import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { FireLink } from '@nx/fire/link'

import { CommonButtonProps } from './types'

export interface ButtonStyleProps {
  $variant: Required<CommonButtonProps['variant']>
  $size: NonNullable<CommonButtonProps['size']>
  $endIcon?: CommonButtonProps['endIcon']
  $startIcon?: CommonButtonProps['startIcon']
}

export const baseFireButtonStyles = ({
  $variant,
  $size,
  $endIcon,
  $startIcon,
}: ButtonStyleProps) => css`
  ${({ theme }) => css`
    height: ${$variant === 'ghost' ? '30px' : $size === 'S' ? '32px' : '42px'};
    ${$size === 'S' ? theme.typography.body.S : theme.typography.body.M}
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px 15px;
    ${$endIcon &&
    css`
      padding-right: 5px;
    `}
    ${$startIcon &&
    css`
      padding-left: 5px;
    `}
    margin: 0;
    text-decoration: none;
    border: none;
    transition:
      border-color 333ms ease-in-out,
      background 333ms ease-in-out;
    cursor: pointer;
    position: relative;

    &:disabled:hover {
      cursor: not-allowed;
    }
  `}
`

export const styledPrimaryButtonStyles = css`
  ${({ theme: { colours } }) => css`
    background: ${colours.primary.primary};
    border: 2px solid ${colours.primary.primary};
    color: ${colours.primary.white};

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      background: ${colours.primary.white};
      color: ${colours.primary.primary};
      border-color: ${colours.grey['30']};
    }

    &:disabled {
      background: ${colours.grey['75']};
      color: ${colours.primary.white};
      border-color: ${colours.grey['75']};
    }
  `}
`

export const StyledBaseButton = styled.button<ButtonStyleProps>`
  ${({ $variant, $size, $endIcon, $startIcon }) =>
    baseFireButtonStyles({ $variant, $size, $endIcon, $startIcon })}
`

export const StyledButton = styled(StyledBaseButton)<ButtonStyleProps>`
  ${({ $variant, $size }) => css`
    ${$variant === 'primary' && styledPrimaryButtonStyles}
    ${$variant === 'secondary' &&
    css`
      ${({ theme: { colours } }) => css`
        background: ${colours.primary.white};
        border: 2px solid ${colours.primary.primary};
        color: ${colours.primary.primary};

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          background: ${rgba(colours.primary.primary, 0.1)};
          color: ${colours.primary.primary};
          text-decoration: none;
        }

        &:disabled {
          border: 2px solid ${colours.grey['75']};
          color: ${colours.grey['75']};
        }
      `}
    `}
    ${$variant === 'ghost' &&
    css`
      ${({ theme: { colours } }) => css`
        background: none;
        border: none;
        color: ${colours.primary.black};
        padding: 0;

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          background: none;
          color: ${colours.primary.primary};
          text-decoration: underline;
        }

        &:disabled {
          border: none;
          color: ${colours.grey['75']};
        }
      `}
    `}
    ${$variant === 'label' &&
    css`
      ${({ theme: { colours } }) => css`
        background: none;
        border: none;
        color: ${colours.primary.black};
      `}

      ${({ theme: { colours, typography } }) => css`
        ${typography.label[$size]}
        color: ${colours.primary.primary};
        padding: 0;

        svg {
          flex-shrink: 0;
        }

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          background: none;
          text-decoration: underline;
        }

        &:disabled {
          border: none;
          color: ${colours.grey['75']};
        }
      `}
    `}
  `}
`

const Icon = styled.svg<{
  $size: ButtonStyleProps['$size']
}>`
  ${({ $size }) => css`
    height: ${$size === 'S' ? '20px' : '25px'};
    width: ${$size === 'S' ? '20px' : '25px'};
    display: grid;
    place-items: center;
  `}
`

export const StyledStartIcon = styled(Icon)`
  margin-right: 5px;
`

export const StyledEndIcon = styled(Icon)`
  margin-left: 5px;
`

export const StyledFireLink = styled(FireLink)<{
  $variant: CommonButtonProps['variant']
}>`
  ${({ $variant }) =>
    $variant === 'primary' || $variant === 'secondary'
      ? css`
          &:hover,
          &:focus {
            text-decoration: none;
          }
        `
      : null}
`
