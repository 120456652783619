import type { AppContext } from 'next/app'
import { v4 as uuidv4 } from 'uuid'

import {
  BonhamsCookies,
  CustomContext,
  OneTrustCookies,
  OneTrustGroup,
} from '@nx/global-types'
import { parseCookieRequestHeader } from '@nx/cookies'
import { isOneTrustCookieGroupEnabled } from '@nx/one-trust'

export const getCrossingMindsSessionId = ({
  pageContext,
  appContext,
}: {
  pageContext?: CustomContext
  appContext?: AppContext
}): { id: string; shouldPersist: boolean } => {
  let cookies: BonhamsCookies & OneTrustCookies = {}

  if (pageContext?.req?.cookies) {
    cookies = pageContext.req?.cookies
  }

  if (appContext?.ctx.req?.headers?.cookie) {
    cookies = parseCookieRequestHeader(appContext.ctx.req.headers.cookie)
  }

  const isGroupEnabled = isOneTrustCookieGroupEnabled(
    OneTrustGroup.Functional,
    cookies
  )

  return {
    id: cookies.xm_id ? cookies.xm_id : uuidv4(),
    shouldPersist: isGroupEnabled && !cookies.bonhams_sid && !cookies.xm_id,
  }
}
