import { bonhamsTheme, MasterTheme } from '@nx/fire/bonhams-theme-master'
import {
  ReactComponent as skinnerLogoIcon,
  default as skinnerLogoSVG,
} from './assets/skinnerLogo.svg'
import {
  ReactComponent as bonhamsSkinnerLogoIcon,
  default as bonhamsSkinnerLogoSVG,
} from './assets/bonhamsSkinnerLogo.svg'

export const skinnerTheme: MasterTheme = {
  ...bonhamsTheme,
  name: 'skinner',
  colours: {
    ...bonhamsTheme.colours,
    primary: {
      ...bonhamsTheme.colours.primary,
      primary: '#be1e2d',
    },
    secondary: {
      ...bonhamsTheme.colours.secondary,
      primary: '#9c1420',
    },
    tertiary: {
      ...bonhamsTheme.colours.tertiary,
      primary: '#f2eade',
    },
  },
  logo: {
    component: skinnerLogoIcon,
    svg: skinnerLogoSVG,
  },
  footerLogo: {
    component: bonhamsSkinnerLogoIcon,
    svg: bonhamsSkinnerLogoSVG,
  },
}
