// istanbul ignore file

export enum LaunchDarklyFeatureFlags {
  FEATURE_CURRENCY_LOCALIZATION = 'feature_currency_localization',
  FEATURE_CROSSING_MINDS = 'crossing-minds',
  FEATURE_PAYMENTS_PAGE = 'enable-payments-page',
  FEATURE_OM_APP = 'om-app',
  FEATURE_CONDITIONS_PHOTOS = 'enable-conditions-photos',
  FEATURE_NEW_PASSWORD_RESET_FLOW = 'new-forgotten-password',
  FEATURE_GROUPS_FILTER = 'enable-groups-filter',
  FEATURE_ITEM_QUEUE_SERVER_EVENTS = 'item-queue-server-events',
  FEATURE_TYPESENSE_RESULTS_PAGE = 'typesense-results',
  FEATURE_TYPESENSE_UPCOMING_PAGE = 'typesense-upcoming',
  FEATURE_ONLINE_AUCTIONS_ORDERED_BY_AVAILABLE_TO_BID = 'online-auctions-ordered-by-available-to-bid',
  FEATURE_SHOW_SOLD_WITHOUT_RESULTS_LOTS = 'show_sold_without_results_lots',
  FEATURE_REGISTER_NOW_IN_LOT_CARD = 'show_register_now_in_lot_card',
  FEATURE_NEW_INVOICES = 'new-invoices',
  FEATURE_LOT_SOLD_NOTIFICATION = 'enable-lot-sold-notification',
}
