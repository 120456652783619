import { useEffect, useState } from 'react'

import { getTranslate } from '@nx/translations'

import {
  ButtonNavItem,
  LinkNavItem,
  StyledChevronDownIcon,
  StyledLanguageNav,
} from '../../fire-header.styles'
import translate from './language-nav.translate.json'

const { text } = getTranslate(translate)

export function LanguageNav({
  toggleVisible,
  show,
  uniqueName,
  links,
  $isMobileOnly,
}: LanguageNavProps) {
  const [activePath, setActivePath] = useState<string>()

  useEffect(() => {
    setActivePath(document.location.pathname)
  }, [])

  return (
    <>
      <ButtonNavItem
        variant="ghost"
        onClick={() => toggleVisible((prevValue) => !prevValue)}
        aria-haspopup="true"
        aria-controls={uniqueName}
        aria-expanded={show}
        data-testid={`${uniqueName}-control`}
        $isExpanded={show}
        $isMobileOnly={$isMobileOnly}
      >
        {text('title')}
        <StyledChevronDownIcon />
      </ButtonNavItem>

      <StyledLanguageNav
        id={uniqueName}
        aria-label={text('title')}
        data-testid={uniqueName}
        aria-hidden={!show}
        $visible={show}
      >
        {links.map(({ text, href }) => (
          <LinkNavItem
            key={`${uniqueName}-${text}-${$isMobileOnly}`}
            href={href}
            forceAnchor
            $isActive={activePath === href}
            $isMobileOnly={$isMobileOnly}
          >
            {text}
          </LinkNavItem>
        ))}
      </StyledLanguageNav>
    </>
  )
}

interface LanguageNavProps {
  toggleVisible: React.Dispatch<React.SetStateAction<boolean>>
  show: boolean
  links: {
    text: string
    href: string
  }[]
  uniqueName: string
  $isMobileOnly?: boolean
}
