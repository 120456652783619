import getConfig from 'next/config'
import { useEffect } from 'react'

import { useGetLaunchDarklyFlags } from '@nx/external/launch-darkly-client'
import { useDataLayer } from '@nx/utils/google-tag-manager'

const { publicRuntimeConfig } = getConfig()

const getCommaSeparatedValues = (obj: Record<string, unknown>) =>
  Object.entries(obj)
    .map(([key, value]) => `${key}:${value}`)
    .join(';')

export const useUserFeatureFlagTracking = () => {
  const dataLayer = useDataLayer()
  const { data: flags } = useGetLaunchDarklyFlags(
    publicRuntimeConfig.LAUNCHDARKLY_CLIENT_KEY
  )

  useEffect(() => {
    if (!window?.dataLayer?.some((item) => item?.featureFlags)) {
      if (flags && Object.keys(flags).length) {
        dataLayer.push({
          featureFlags: getCommaSeparatedValues(flags),
        })
      }
    }
  }, [dataLayer, flags])
}
