import getConfig from 'next/config'

import { RedirectConfig } from './redirectMappingsTypes'

const { publicRuntimeConfig } = getConfig()

const baseUrl = publicRuntimeConfig.WEB_BASE_URL
const cornetteBaseUrl = publicRuntimeConfig.CORNETTE_BASE_URL
const cornetteSmartlingBaseUrl = publicRuntimeConfig.CORNETTE_SMARTLING_BASE_URL

const cornetteURL = new URL(cornetteBaseUrl)
const cornetteSmartlingURL = new URL(cornetteSmartlingBaseUrl)

const redirects: Pick<RedirectConfig, 'source' | 'destination'>[] = [
  {
    source:
      /(^(?!(^\/$|\/about-us\/|\/404\/|\/logout\/|\/services\/|\/robots.txt)).*$)/,
    destination: `${baseUrl}{{0}}`,
  },
]

export const cornetteSubDomainRedirects: RedirectConfig[] = redirects.flatMap(
  (redirect) => [
    {
      hostname: cornetteURL.hostname,
      permanent: true,
      ...redirect,
    },
    {
      hostname: cornetteSmartlingURL.hostname,
      permanent: true,
      ...redirect,
    },
  ]
)
