import { OneTrustCookies, OneTrustGroup } from '@nx/global-types'

export const isOneTrustCookieGroupEnabled = (
  cookieGroup: OneTrustGroup,
  cookies: OneTrustCookies
) => {
  const oneTrustGroups =
    new URLSearchParams(cookies.OptanonConsent).get('groups') || ''

  if (!oneTrustGroups) {
    return true // opt in by default
  }

  const groups = Object.fromEntries(
    oneTrustGroups
      .split(',')
      .map((group) => group.split(':') as [string, string])
  )

  // is the group enabled?
  return groups[cookieGroup] === '1'
}
