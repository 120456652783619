import { Body, BodyProps } from '@nx/fire/typography'
import styled, { css } from 'styled-components'

export const MaintenanceWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex.stickyMaintenanceBar};
    background-color: ${theme.colours.primary.black};
  `}
`

export const MaintenanceText = styled(Body)<BodyProps>`
  ${({ theme }) => css`
    padding: 25px 14px;
    color: ${theme.colours.primary.white};
  `}
`
