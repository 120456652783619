function cryptXOR(content: string, key = 85) {
  let output = ''

  for (let i = 0; i < content.length; i++) {
    output += String.fromCharCode(key ^ content.charCodeAt(i))
  }

  return output
}

export const encryptXOR = (stringToEncrypt: string, key?: number): string => {
  return encodeURIComponent(cryptXOR(stringToEncrypt, key))
}

export const decryptXOR = (stringToDecrypt: string, key?: number): string => {
  return cryptXOR(decodeURIComponent(stringToDecrypt), key)
}
